import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import guidance from "../assets/img/HomePage/guidance.svg";
import ModalAbout from "../components/ModalAbout";
import { makeStyles } from "@mui/styles";
import {
  Box,
  Chip,
  CircularProgress,
  Typography,
  useTheme,
} from "@mui/material";
import Options from "../components/Options";
import Search from "../assets/img/Entities/SearchGuide.svg";
import caretUp from "../assets/img/Entities/caretUp.svg";
import caretDown from "../assets/img/Entities/caretDown.svg";
import caretRight from "../assets/img/Entities/caretRight.svg";
import caretUrdu from "../assets/img/Entities/caretUrdu.svg";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import { changeTab } from "../redux/TabSlice";
import {
  getAllServiceProviders,
  getServiceProviders,
  setSelectedItem,
} from "../redux/OrganizationSlice";
import { getTilte } from "../helpers/utils";
import { setSelectedProvider } from "../redux/AppContentSlice";
import { getSelectedGuidance, setSelectedGuide } from "../redux/OffenceSlice";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import ArrowBack from "../assets/img/HumQadamHome/arrowBack.svg";
import { setBackToGuide } from "../redux/PageSlice";

const useStyles = makeStyles((theme) => ({
  bgcolor: {
    background: "#FFFFFF",
  },
  textColor: {
    color: theme.palette.guidance.main,
  },
  input: {
    "&::placeholder": {
      color: theme.palette.guidance.main,
    },
  },

  sectionbg: {
    background: theme.palette.primary.main,
  },

  sectionBgLight: {
    backgroundColor: "#FFE2E3",
  },

  guidanceBg: {
    backgroundColor: "#EFF2DC",
  },

  guidanceTextBg: {
    backgroundColor: theme.palette.guidance.main,
  },
  fullScreen: {
    minHeight: "100vh",
  },
  height: {
    maxHeight: "50vh",
  },
}));

function GuideStep() {
  const theme = useTheme();
  const classes = useStyles();
  const { selectedGuide } = useSelector((state) => state.offence);
  const { appContent } = useSelector((state) => state.appContent);
  const { selLanguageId, isEnglish } = useSelector((state) => state.language);
  const { selDistrictId } = useSelector((state) => state.district);
  const { providers } = useSelector((state) => state.organizations);
  const [openAbout, setOpenAbout] = useState(false);
  const [openItem, setOpenItem] = useState(false);
  const [guidanceTitle, setGuidanceTitle] = useState("");
  const [stepsTitle, setStepsTitle] = useState("");
  const [dropdown, setDropdown] = useState(0);
  const handleOpen = () => setOpenAbout(true);
  const handleClose = () => setOpenAbout(false);

  const handleCloseItem = () => setOpenItem(false);
  const [tabSelect, setTabSelect] = useState("GuideStep");
  const dispatch = useDispatch();

  // useEffect(() => {
  //   if (selectedGuide) {
  //     dispatch(
  //       getSelectedGuidance({
  //         id: selectedGuide._id,
  //         langId: selLanguageId,
  //         district: selDistrictId,
  //       })
  //     );
  //   }
  // }, [selLanguageId]);

  useEffect(() => {
    // dispatch(setSelectedGuide(null));
    dispatch(changeTab(tabSelect));
  }, [tabSelect]);

  const getProviders = (providersId) => {
    let arr = providers.filter((obj) => {
      return Boolean(providersId.find((id) => id === obj._id));
    });
    return arr;
  };

  useEffect(() => {
    if (appContent) {
      setGuidanceTitle(getTilte(appContent, "Step by Step Guidance"));
      setStepsTitle(getTilte(appContent, "Steps"));
    }
  }, [appContent, selLanguageId]);

  const steps = [
    {
      id: 1,
      text: "About",
    },
    {
      id: 2,
      text: "Partners",
    },
    {
      id: 3,
      text: "Privacy Policy",
    },
  ];
  const handleDropdown = (id) => {
    if (id === dropdown) {
      setDropdown(0);
    } else {
      setDropdown(id);
    }
  };

  if (!selectedGuide)
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          height: "300px",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );

  return (
    <div
      className={`${classes.guidanceBg} pb-20 flex flex-col justify-between`}
    >
      <div>
        <Header />
        <div className="flex-col md:flex-row flex justify-between">
          <div className=" block md:hidden mb-5">
            <Options handleOpen={handleOpen} />
          </div>
          <div className="flex mx-5 mt-2 content-center items-center">
            <img className="w-6 mr-3 mb-2" src={guidance} alt="Emergency" />
            <div className="flex flex-col md:flex-row">
              <Typography
                onClick={() => setTabSelect("StepGuide")}
                className="mr-3 cursor-pointer"
                color={"black"}
                variant="p"
              >
                {guidanceTitle} {">"}
              </Typography>
              <Typography className="font-bold" color={"black"} variant="p">
                {selectedGuide?.title}
              </Typography>
            </div>
          </div>
          <div className=" hidden md:block">
            <Options handleOpen={handleOpen} />
          </div>
        </div>
        <div className="flex flex-col justify-center content-center items-center my-2 w-60 sm:w-72 md:w-6/12 mx-auto">
          {/* <Typography className={`${classes.textColor} font-bold text-lg mb-2`} variant="p">
            {selectedGuide.title}
          </Typography> */}
          <Typography
            className={`${classes.textColor} my-4 text-center`}
            variant="p"
          >
            {selectedGuide?.description}
          </Typography>
        </div>
        <div>
          <Typography
            className={`w-full block text-center ${classes.guidanceTextBg} p-4 font-bold`}
            color={"white"}
            variant="p"
          >
            {stepsTitle}
          </Typography>
        </div>
        <div className={`${classes.guidanceBg} ${classes.guidanceBg} mb-20`}>
          {selectedGuide?.guidance_steps?.map((step) => (
            <div key={step._id}>
              <div
                onClick={() => handleDropdown(step._id)}
                className={clsx(
                  "flex cursor-pointer w-60 sm:w-72 md:w-8/12 mx-auto",
                  isEnglish ? "flex-row" : "flex-row-reverse"
                )}
              >
                <img
                  className=" w-4"
                  src={
                    step.id === dropdown
                      ? caretDown
                      : isEnglish
                      ? caretRight
                      : caretUrdu
                  }
                  alt="Caret"
                />
                <Typography
                  className={`${classes.textColor} p-4 font-bold`}
                  variant="p"
                >
                  {step.title}
                </Typography>
              </div>

              {dropdown === step._id && (
                <div className="w-full bg-white flex flex-col justify-center py-4 pb-6 items-center">
                  {step?.guidance_step_description?.map((stepDes, index) => (
                    <div
                      key={stepDes._id}
                      className="w-60 sm:w-72 md:w-8/12 mt-2"
                    >
                      <div
                        className={clsx(
                          "flex",
                          isEnglish ? "flex-row" : "flex-row-reverse"
                        )}
                      >
                        <Typography
                          className={clsx(classes.textColor, "block mx-2")}
                          variant="p"
                        >
                          {index + 1})
                        </Typography>
                        <Typography
                          className={clsx(
                            classes.textColor,
                            "mb-3 block",
                            isEnglish ? " text-left" : " text-right"
                          )}
                          variant="p"
                        >
                          {stepDes?.description}
                        </Typography>
                      </div>
                      <div
                        className={clsx(
                          " mb-4",
                          isEnglish ? " float-left" : " float-right"
                        )}
                      >
                        {getProviders(stepDes?.service_providers)?.map(
                          (provider) => (
                            <p
                              key={provider._id}
                              className={clsx(
                                "p-4 rounded-2xl m-1 cursor-pointer inline-block text-xs text-white",
                                isEnglish ? "text-left" : "text-right",
                                classes.guidanceTextBg
                              )}
                              onClick={() => {
                                dispatch(setBackToGuide(true));
                                dispatch(setSelectedItem(provider));
                                dispatch(changeTab("HelplineDetails"));
                              }}
                            >
                              {provider.title}
                            </p>
                          )
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
      <img
        alt="Arrow Back"
        onClick={() => setTabSelect("StepGuide")}
        src={ArrowBack}
        className="mt-16 cursor-pointer self-start ml-5"
      />
      <div>
        <ModalAbout open={openAbout} handleClose={handleClose} head="About" />
      </div>
    </div>
  );
}

export default GuideStep;
