import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import ModalAbout from "../components/ModalAbout";
import { makeStyles } from "@mui/styles";
import {
  Box,
  CircularProgress,
  Grid,
  Typography,
  createTheme,
} from "@mui/material";
import Options from "../components/Options";
import Guard from "../assets/img/Entities/IslamicLaw.svg";
import { changeTab } from "../redux/TabSlice";
import { useDispatch, useSelector } from "react-redux";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import clsx from "clsx";
import {
  getGuidance,
  getLaws,
  getSelectedGuidance,
  getSelectedLaw,
  setSelectedGuide,
  setSelectedLaw,
} from "../redux/OffenceSlice";
import { getTilte } from "../helpers/utils";
import ArrowBack from "../assets/img/HumQadamHome/arrowBack.svg";
import { getAllServiceProviders } from "../redux/OrganizationSlice";

// const theme = createTheme();

const useStyles = makeStyles((theme) => ({
  bgcolor: {
    background: "#FFFFFF",
  },

  sectionbg: {
    background: theme.palette.primary.main,
  },

  sectionBgLight: {
    backgroundColor: "#F6F1F4",
  },

  lawsBg: {
    backgroundColor: "#BCACE3",
  },

  lawsTextBg: {
    backgroundColor: theme.palette.laws.main,
  },
}));

function EntitiesGrid({
  bgColor,
  bgTextColor,
  img,
  page,
  title,
  nextPage,
  textDark,
}) {
  const classes = useStyles();
  const { tab } = useSelector((state) => state.tab);
  const { selLanguageId } = useSelector((state) => state.language);
  const { selDistrictId } = useSelector((state) => state.district);
  const { selProvinceId } = useSelector((state) => state.province);
  const { offenceLaws } = useSelector((state) => state.offence);
  const { offenceGuidance } = useSelector((state) => state.offence);
  const { appContent } = useSelector((state) => state.appContent);
  const [description, setDescription] = useState("");
  const [openAbout, setOpenAbout] = useState(false);
  const { providers } = useSelector((state) => state.organizations);
  const [tabSelect, setTabSelect] = useState(page);
  const [showData, setShowData] = useState([]);
  const dispatch = useDispatch();
  const handleOpen = () => setOpenAbout(true);
  const handleClose = () => {
    setOpenAbout(false);
  };

  const items = [
    {
      id: 1,
      text: "About",
      num: "15",
    },
    {
      id: 2,
      text: "Partners",
      num: "15",
    },
    {
      id: 3,
      text: "Privacy Policy",
      num: "15",
    },
    {
      id: 3,
      text: "Privacy Policy",
      num: "15",
    },
    {
      id: 3,
      text: "Privacy Policy",
      num: "15",
    },
    {
      id: 3,
      text: "Privacy Policy",
      num: "15",
    },
    {
      id: 3,
      text: "Privacy Policy",
      num: "15",
    },
    {
      id: 4,
      text: "Terms and Conditions",
      num: "15",
    },
    {
      id: 4,
      text: "Terms and Conditions",
      num: "15",
    },
    {
      id: 4,
      text: "Terms and Conditions",
      num: "15",
    },
    {
      id: 4,
      text: "Terms and Conditions",
      num: "15",
    },
    {
      id: 4,
      text: "Terms and Conditions",
      num: "15",
    },
    {
      id: 4,
      text: "Terms and Conditions",
      num: "15",
    },
  ];

  useEffect(() => {
    dispatch(setSelectedGuide(null));
  }, []);

  useEffect(() => {
    if (selLanguageId) {
      if (tab === "Laws") {
        dispatch(getLaws({ langId: selLanguageId }));
      }
    }
  }, [selLanguageId]);

  useEffect(() => {
    if (selLanguageId && selDistrictId) {
      if (tab !== "Laws") {
        dispatch(
          getGuidance({ district: selDistrictId, langId: selLanguageId })
        );
        if (providers.length === 0 && selDistrictId) {
          dispatch(
            getAllServiceProviders({ selDistrictId, langId: selLanguageId })
          );
        }
      }
    }
  }, [selLanguageId, selDistrictId]);

  useEffect(() => {
    dispatch(changeTab(tabSelect));
  }, [tabSelect]);

  useEffect(() => {
    if (tab === "Laws") {
      setShowData(offenceLaws);
    } else {
      setShowData(offenceGuidance);
    }
  }, [tab, offenceLaws, offenceGuidance]);

  const handleClick = (nextPage, item) => {
    if (tab === "Laws") {
      dispatch(getSelectedLaw({ id: item.id, langId: selLanguageId }));
    } else {
      dispatch(
        getSelectedGuidance({
          id: item.id,
          langId: selLanguageId,
          district: selDistrictId,
        })
      );
    }
    setTabSelect(nextPage);
  };

  useEffect(() => {
    if (appContent) {
      if (tab === "Laws") {
        setDescription(getTilte(appContent, "EntitiesLawDes"));
      } else {
        setDescription(getTilte(appContent, "EntitiesGuideDes"));
      }
    }
  }, [appContent]);

  const data = [
    {
      id: 1,
      text: "About",
    },
    {
      id: 2,
      text: "Partners",
    },
    {
      id: 3,
      text: "Privacy Policy",
    },
    {
      id: 4,
      text: "Terms and Conditions",
    },
  ];
  return (
    <div
      className={` w-full pb-10 flex flex-col justify-between`}
      style={{ background: bgColor }}
    >
      <Header />
      <Options setShowData={setShowData} handleOpen={handleOpen} />
      {showData?.length === 0 ? (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </Box>
      ) : (
        <div className="flex flex-col justify-center content-center items-center mb-20">
          <img className=" w-12 mb-2" src={img} alt="Emergency" />
          <Typography
            style={{ color: textDark ? textDark : bgTextColor }}
            className="text-lg font-bold my-2"
            variant="p"
          >
            {title}
          </Typography>
          <Typography color={"black"} className="text-center w-4/6" variant="p">
            {description}
          </Typography>

          <Grid className="p-5" container spacing={2}>
            {showData.map((item, index) => (
              <Grid
                item
                xs={12}
                sm={6}
                md={3}
                lg={2}
                key={index}
                className="flex justify-center"
              >
                <div
                  onClick={() => {
                    handleClick(nextPage, item);
                  }}
                  className={clsx(
                    "cursor-pointer py-5 w-full sm:w-36 rounded-3xl"
                  )}
                  style={{ background: bgTextColor }}
                >
                  <img
                    className="h-16 mb-5 mx-auto"
                    src={item.icon}
                    alt="Guard"
                  />
                  <Typography
                    color={"white"}
                    className="block text-center text-xs"
                    variant="p"
                  >
                    {item.title}
                  </Typography>
                </div>
              </Grid>
            ))}
          </Grid>
        </div>
      )}
      <img
        alt="Arrow Back"
        onClick={() => setTabSelect("HomePage")}
        src={ArrowBack}
        className="cursor-pointer self-start ml-5"
      />
      <div>
        <ModalAbout open={openAbout} handleClose={handleClose} />
      </div>
    </div>
  );
}

export default EntitiesGrid;
