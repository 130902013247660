import React from "react";
import shirkat from "../assets/img/HomePage/footerShirkat.svg";
import { createTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const theme = createTheme();

const useStyles = makeStyles((theme) => ({
  footerbg: {
    backgroundColor: "#393939",
  },
}));

const Footer = () => {
  const classes = useStyles();
  const resource = [
    {
      id: 1,
      text: "Reproductive Health",
      url: "https://shirkatgah.org/reproductive-health/",
    },
    {
      id: 2,
      text: "Posters and Brochures",
      url: "https://shirkatgah.org/posters-and-brochures/",
    },
    {
      id: 3,
      text: "Women, Law and Rights",
      url: "https://shirkatgah.org/women-law-and-rights/",
    },
    {
      id: 4,
      text: "Reports and Policy Briefs",
      url: "https://shirkatgah.org/reports-and-policy-briefs/",
    },
    {
      id: 5,
      text: "Women Living Under Muslim Laws",
      url: "https://shirkatgah.org/wluml-women-living-under-muslim-laws/",
    },
    {
      id: 6,
      text: "Green Economics and Globalization",
      url: "https://shirkatgah.org/green-economics-and-globalization/",
    },
  ];

  const guide = [
    {
      id: 1,
      text: "Sindh",
    },
    {
      id: 2,
      text: "Punjab",
    },
    {
      id: 3,
      text: "Khyber Pakhtunkhwa",
    },
    {
      id: 4,
      text: "Balochistan",
    },
  ];

  const contact = [
    {
      id: 1,
      text: "Contact No: (+92-42) 35838815",
    },
    {
      id: 2,
      text: "Email: info@sgah.org.pk",
    },
    {
      id: 3,
      text: "Address: 68 Tipu Block Garden Town, Lahore, Punjab",
    },
  ];
  return (
    <div className={`${classes.footerbg}`}>
      <div className="container flex flex-col md:flex-row mx-auto p-10 justify-evenly">
        <div className="pr-10 mb-6 md:mb-0">
          <img
            className="object-contain mt-2 md:w-52 w-24"
            src={shirkat}
            alt="Shirkat Gah"
          />
        </div>
        <div className="pr-10 mb-6 md:mb-0  text-white">
          <h1 className="mb-2">Resource Center</h1>
          <ul>
            {resource.map((res) => (
              <li key={res.id} className="text-xs mb-1">
                <a href={res.url}>{res.text}</a>
              </li>
            ))}
          </ul>
        </div>
        <div className="pr-10 mb-6 md:mb-0 text-white">
          <h1 className="mb-2">Guide To Services</h1>
          <ul>
            {guide.map((res) => (
              <li key={res.id} className="text-xs">
                {res.text}
              </li>
            ))}
          </ul>
        </div>
        <div className="pr-10 mb-6 md:mb-0  text-white">
          <h1 className="mb-2">Contact</h1>
          <ul>
            {contact.map((res) => (
              <li key={res.id} className="text-xs">
                {res.text}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Footer;
