import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import homeImage from "../assets/img/HomePage/homeImage.svg";
import playStore from "../assets/img/HomePage/googlePlay.svg";
import appStore from "../assets/img/HomePage/appStore.svg";
import UNTF from "../assets/img/HomePage/UNTF.svg";
import lums from "../assets/img/HomePage/LUMS1.svg";
import shirkatGah from "../assets/img/HomePage/shirkat.svg";
import emergency from "../assets/img/HomePage/emergency.svg";
import referral from "../assets/img/HomePage/referral.svg";
import guidance from "../assets/img/HomePage/guidance.svg";
import laws from "../assets/img/HomePage/laws.svg";
import minority from "../assets/img/HomePage/minority.svg";
import acid from "../assets/img/HomePage/acid.svg";
import marriage from "../assets/img/HomePage/marriageLaws.svg";
import assault from "../assets/img/HomePage/assault.svg";
import childLaws from "../assets/img/HomePage/childLaws.svg";
import cyberCrime from "../assets/img/HomePage/cyberCrime.svg";
import aboveFooter from "../assets/img/HomePage/aboveFooter.svg";
import webApp from "../assets/img/HomePage/webApp.svg";
import Footer from "../components/Footer";
import Modal from "../components/Modal";
import HomePopUp from "../components/HomePopUp";
import modalTab1 from "../assets/img/HomePage/modalTab1.svg";
import modalTab2 from "../assets/img/HomePage/modalTab2.svg";
import modalTab3 from "../assets/img/HomePage/modalTab3.svg";
import EmergencyServices from "./EmergencyServices";
import ReferralDirectory from "./ReferralDirectory";
import Helplines from "./Helplines";
import HelplineDetails from "./HelplineDetails";
import RelevantLaws from "./RelevantLaws";
import ServicesDetail from "./ServicesDetail";
import GuideStep from "./GuideStep";
import About from "./About";
import Settings from "./Settings";
import Contact from "./Contact";
import { makeStyles } from "@mui/styles";
import { Dialog, Slide, useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { changeTab } from "../redux/TabSlice";
import EntitiesGrid from "./EntitiesGrid";
import { getLanguage, setLanguageId } from "../redux/LanguageSlice";
import { getProvince, setProvinceId } from "../redux/ProvinceSlice";
import {
  getDistrict,
  setDistrict,
  setDistrictId,
  setProvDistrict,
} from "../redux/DistrictSlice";
import { getAppContent } from "../redux/AppContentSlice";
import { getTilte } from "../helpers/utils";
import clsx from "clsx";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  bgcolor: {
    background: "#FFFFFF",
  },
  textColor: {
    color: theme.palette.primary.main,
  },
  texthome: {
    color: theme.palette.secondary.main,
  },

  sectionbg: {
    background: theme.palette.primary.main,
  },
  emergencyBg: {
    backgroundColor: theme.palette.emergency.light,
  },

  emergencyTextBg: {
    backgroundColor: theme.palette.emergency.main,
  },
  referralBg: {
    backgroundColor: theme.palette.referral.light,
  },

  referralTextBg: {
    backgroundColor: theme.palette.referral.main,
  },
  guidanceBg: {
    backgroundColor: theme.palette.guidance.light,
  },

  guidanceTextBg: {
    backgroundColor: theme.palette.guidance.main,
  },
  lawsBg: {
    backgroundColor: theme.palette.laws.light,
  },

  lawsTextBg: {
    backgroundColor: theme.palette.laws.main,
  },
  sectionBgLight: {
    backgroundColor: "#F6F1F4",
  },
  videoContainer: {
    display: "flex",
    flexDirection: "row",
    gap: "10px", // Adjust the gap between the videos
    [theme.breakpoints.down("xl")]: {
      flexDirection: "column", // Stack videos vertically on extra-large screens
    },
  },
  frame: {
    height: "400px",
    width: "700px",
    marginTop: "10px",
    marginLeft: 0,
    [theme.breakpoints.up("xl")]: {
      height: "300px",
      width: "500px",
      marginTop: "10px",
      marginLeft: 5,
    },
    [theme.breakpoints.down("md")]: {
      height: "200px",
      width: "300px",
      marginTop: "10px",
      marginLeft: 0,
    },
  },
}));

const HomePage = () => {
  const theme = useTheme();
  const classes = useStyles();
  const { language, selLanguageId } = useSelector((state) => state.language);
  const { district, provDistrict, allDistricts } = useSelector(
    (state) => state.district
  );
  const { appContent } = useSelector((state) => state.appContent);
  const { province } = useSelector((state) => state.province);
  const [openLanguage, setOpenLanguage] = React.useState(false);
  const [openProvince, setOpenProvince] = React.useState(false);
  const [openDistrict, setOpenDistrict] = React.useState(false);
  const [nationalTitle, setNationalTitle] = useState("");
  const [provincialTitle, setProvincialTitle] = useState("");
  const [serviceTitle, setServiceTitle] = useState("");
  const [guidanceTitle, setGuidanceTitle] = useState("");
  const [lawsTitle, setLawsTitle] = useState("");
  const { tab } = useSelector((state) => state.tab);
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    dispatch(getLanguage());
  }, []);

  useEffect(() => {
    if (selLanguageId) {
      dispatch(getDistrict({ selLanguageId }));
      dispatch(getProvince({ selLanguageId }));
    }
  }, [selLanguageId]);

  useEffect(() => {
    if (allDistricts.length > 0) {
      let arr = allDistricts.filter((dis) => provDistrict?.includes(dis._id));
      dispatch(setDistrict(arr));
    }
  }, [provDistrict, selLanguageId, allDistricts]);

  useEffect(() => {
    dispatch(getAppContent({ selLanguageId }));
  }, [selLanguageId]);

  const handleOpen = () => {
    if (selLanguageId) setOpen(true);
    else setOpenLanguage(true);
  };

  const onClose = () => {
    console.log("Closing");
    setOpenLanguage(false);
    setOpenProvince(false);
    setOpenDistrict(false);
    dispatch(setLanguageId(""));
    dispatch(setDistrictId(""));
    dispatch(setProvinceId(""));
    dispatch(setProvDistrict(""));
  };

  const handleClose = () => {
    if (openLanguage) {
      setOpenLanguage(false);
      setOpenProvince(true);
    } else if (openProvince) {
      setOpenProvince(false);
      setOpenDistrict(true);
    } else {
      setOpenDistrict(false);
      setOpen(true);
    }
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClickClose = () => {
    dispatch(changeTab("HumQadam"));
    setOpen(false);
  };

  useEffect(() => {
    if (appContent) {
      setGuidanceTitle(getTilte(appContent, "Step by Step Guidance"));
      setLawsTitle(getTilte(appContent, "Relevant Laws"));
      setNationalTitle(getTilte(appContent, "National Helplines"));
      setProvincialTitle(getTilte(appContent, "Provincial Helplines"));
      setServiceTitle(getTilte(appContent, "Service Providers"));
    }
  }, [appContent]);

  const componentToRender = () => {
    switch (tab) {
      case "HumQadam":
        return <HomePopUp onClose={handleClickClose} />;
      case "Home":
        return <HomePopUp onClose={handleClickClose} />;
      case "EmergencyServices":
        return <EmergencyServices />;
      case "PoliceServices":
        return <ServicesDetail />;
      case "MedicalServices":
        return <ServicesDetail />;
      case "Laws":
        return (
          <EntitiesGrid
            bgColor="#BCACE3"
            bgTextColor={theme.palette.laws.main}
            textDark="#271258"
            img={laws}
            page="Laws"
            title={lawsTitle}
            nextPage="RelevantLaws"
          />
        );
      case "StepGuide":
        return (
          <EntitiesGrid
            bgColor="#EFF2DC"
            bgTextColor={theme.palette.guidance.main}
            img={guidance}
            page="StepGuide"
            title={guidanceTitle}
            nextPage="GuideStep"
          />
        );
      case "RelevantLaws":
        return <RelevantLaws />;
      case "GuideStep":
        return <GuideStep />;
      case "Settings":
        return <Settings />;
      case "Contact":
        return <Contact />;
      case "ReferralDirectory":
        return <ReferralDirectory />;
      case "Provincial Helplines":
        return <Helplines title={provincialTitle} />;
      case "National Helplines":
        return <Helplines title={nationalTitle} />;
      case "Service Providers":
        return <Helplines title={serviceTitle} />;
      case "HelplineDetails":
        return <HelplineDetails />;
      case "About":
      case "Partners":
      case "Privacy":
      case "Terms":
        return <About />;
      default:
        return <HomePopUp onClose={handleClickClose} />;
    }
  };

  const images = [
    {
      id: 3,
      image: shirkatGah,
    },
    {
      id: 1,
      image: UNTF,
    },
    {
      id: 2,
      image: lums,
    },
  ];
  return (
    <div>
      <Header main={true} />
      <section className={`${classes.bgcolor}`}>
        <div className="container mx-auto">
          <div className="grid max-w-screen-xl px-4 py-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12 place-content-center">
            <div className="mr-auto place-self-center lg:col-span-8">
              <h1
                className={`max-w-2xl mb-4 text-2xl font-extrabold tracking-tight leading-none md:text-2xl xl:text-3xl ${classes.texthome} px-4`}
              >
                A Gender-Based Violence <br></br>(GBV) response application
              </h1>
              {/* <div className="flex w-1/2 md:w-full mx-auto flex-col lg:flex-row">
                <img
                  src={playStore}
                  alt="Playstore"
                  className="inline-flex w-32 object-contain self-center items-center mr-1 justify-center py-3 text-base font-medium text-center"
                />
                <img
                  src={appStore}
                  alt="Appstore"
                  className="inline-flex w-28 object-contain self-center items-center justify-center ml-2 mr-3  py-3 text-base font-medium text-center"
                />
                <img
                  src={webApp}
                  onClick={handleOpen}
                  alt="Web App"
                  className="inline-flex cursor-pointer w-28 object-contain self-center items-center justify-center mr-1  py-3 text-base font-medium text-center"
                />
              </div> */}
              <div className=" flex-col md:flex-row flex">
                <a
                  className="inline-flex self-center items-center mr-1 justify-center py-3 "
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://play.google.com/store/apps/details?id=com.shirkatgah.humqadam"
                >
                  <img
                    src={playStore}
                    alt="Playstore"
                    className="w-32 object-containtext-base font-medium text-center"
                  />
                </a>
                <a
                  className="inline-flex self-center items-center justify-center ml-2 mr-3  py-3 "
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://apps.apple.com/pk/app/humqadam/id6463237568"
                >
                  <img
                    src={appStore}
                    alt="Appstore"
                    className="inline-flex w-28 object-containtext-base font-medium text-center"
                  />
                </a>
                <img
                  src={webApp}
                  onClick={handleOpen}
                  alt="Web App"
                  className="inline-flex cursor-pointer w-28 object-contain self-center items-center justify-center mr-1  py-3 text-base font-medium text-center"
                />
              </div>
            </div>
            <div className="hidden object-contain lg:mt-0 lg:col-span-4 lg:flex">
              <img src={homeImage} alt="mockup" />
            </div>
          </div>
        </div>
        <div className="flex px-4 justify-center flex-col md:flex-row">
          {images.map((img) => (
            <div key={img.id} className="place-self-center">
              <img
                src={img.image}
                alt="partners"
                className="inline-flex items-center justify-center pl-3 py-3 text-base font-medium h-28 w-40 mx-3"
              />
            </div>
          ))}
        </div>
        <div className={`mt-6 ${classes.sectionbg}`}>
          <div className="mx-auto container flex flex-col xl:flex-row p-8 justify-center items-center">
            <div>
              <iframe
                className={clsx(classes.frame)}
                src="https://www.youtube.com/embed/knG_6S1ucCc?si=llAsKGmkQlglVA34"
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            </div>
            <div>
              <iframe
                className={clsx(classes.frame)}
                src="https://www.youtube.com/embed/AdkmuTgOMk8?si=1jt2D4JOLE0scYTR"
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            </div>
          </div>
        </div>
        <div className={`${classes.sectionBgLight}`}>
          <div className="container flex flex-col md:flex-row p-12 items-center mx-auto">
            <div
              className={`md:rounded-l-2xl rounded-none ${classes.sectionbg} p-5 text-white w-full h-full lg:h-64 lg:w-7/12`}
            >
              <h1 className=" font-bold text-lg">
                A Gender-Based Violence(GBV) Response Services Application
              </h1>
              <br></br>
              <h2 className="text-lg">
                Humqadam is a result of a collective effort for designing an
                application that can help Women Human Rights Defenders (and
                general public) of Pakistan to respond to cases of gender based
                violence. The app provides quick access to referral services,
                relevant laws and a navigation guide for dealing with specific
                issues of violence.
              </h2>
            </div>
            <div
              className={`${classes.bgcolor} rounded-none md:rounded-3xl md:shadow py-10 px-5 w-full lg:w-5/12`}
            >
              <div className="flex flex-col sm:flex-row justify-center items-center">
                <div
                  className={`m-2 w-36 flex-col ${classes.emergencyBg} rounded-xl`}
                >
                  <div>
                    <img
                      src={emergency}
                      alt="Appstore"
                      className="px-3 py-5 w-20 mx-auto"
                    />
                  </div>
                  <p
                    className={`${classes.emergencyTextBg} text-center rounded-b-xl p-2 text-xs text-white`}
                  >
                    Emergency Services
                  </p>
                </div>
                <div
                  className={`m-2 w-36 flex-col ${classes.referralBg} rounded-xl`}
                >
                  <div>
                    <img
                      src={referral}
                      alt="Appstore"
                      className="px-3 py-5 w-20 mx-auto"
                    />
                  </div>
                  <p
                    className={`${classes.referralTextBg} text-center rounded-b-xl p-2 text-xs text-white`}
                  >
                    Referral Directory
                  </p>
                </div>
              </div>
              <div className="flex flex-col sm:flex-row justify-center items-center">
                <div
                  className={`m-2 w-36 flex-col ${classes.guidanceBg} rounded-xl`}
                >
                  <div>
                    <img
                      src={guidance}
                      alt="Appstore"
                      className="px-3 py-5 h-24 mx-auto"
                    />
                  </div>
                  <p
                    className={`${classes.guidanceTextBg} text-center rounded-b-xl py-2 text-xs text-white`}
                  >
                    Step by Step Guidance
                  </p>
                </div>
                <div
                  className={`m-2 w-36 flex-col ${classes.lawsBg} rounded-xl`}
                >
                  <div>
                    <img
                      src={laws}
                      alt="Appstore"
                      className="px-3 py-5 h-24 mx-auto"
                    />
                  </div>
                  <p
                    className={`${classes.lawsTextBg} text-center rounded-b-xl p-2 text-xs text-white`}
                  >
                    Relevant Laws
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="flex pb-10 items-center justify-center">
            <button
              onClick={handleOpen}
              className={`${classes.sectionbg} shadow-lg shadow-pink-900 px-7 p-3 text-white font-bold text-lg rounded-xl`}
            >
              Get Started! / ایپ لانچ کریں
            </button>
          </div>
        </div>
        <div className="mx-auto container p-10">
          <h2 className={`text-center font-bold text-lg ${classes.textColor}`}>
            Major Laws/Themes of HumQadam
          </h2>
          <div className="p-3 flex flex-col md:flex-row justify-evenly items-center">
            <div className="w-full md:w-1/4">
              <img
                src={acid}
                alt="Appstore"
                className="px-3 py-5 h-24 w-20 mx-auto"
              />
              <p
                className={`text-center rounded-b-xl p-2 text-xs font-bold ${classes.textColor}`}
              >
                ACID CRIMES
              </p>
            </div>
            <div className="w-full md:w-1/4">
              <img
                src={childLaws}
                alt="Appstore"
                className="px-3 py-5 h-24 w-20 mx-auto"
              />
              <p
                className={`text-center rounded-b-xl p-2 text-xs font-bold ${classes.textColor}`}
              >
                LAWS REGARDING CHILDREN<br></br>(CHILD ABUSE, CHILD CUSTODY)
              </p>
            </div>
            <div className="w-full md:w-1/4">
              <img
                src={assault}
                alt="Appstore"
                className="px-3 py-5 h-24 w-20 mx-auto"
              />
              <p
                className={`text-center rounded-b-xl p-2 text-xs font-bold ${classes.textColor}`}
              >
                ASSAULT<br></br>(SEXUAL ASSAULT, BATTERY ETC)
              </p>
            </div>
          </div>
          <div className="p-3 flex flex-col md:flex-row justify-evenly items-center">
            <div className="w-full md:w-1/4">
              <img
                src={marriage}
                alt="Appstore"
                className="px-3 py-5 h-24 w-20 mx-auto"
              />
              <p
                className={`text-center rounded-b-xl p-2 text-xs font-bold ${classes.textColor}`}
              >
                MARRIAGE LAWS <br></br>(EARLY AGE MARRIAGE, FORCE MARRIAGE,
                DESIRE TO TERMINATE)
              </p>
            </div>
            <div className="w-full md:w-1/4">
              <img
                src={minority}
                alt="Appstore"
                className="px-3 py-5 h-24 w-20 mx-auto"
              />
              <p
                className={`text-center rounded-b-xl p-2 text-xs font-bold ${classes.textColor}`}
              >
                MINORITY RIGHTS
              </p>
            </div>
            <div className="w-full md:w-1/4">
              <img
                src={cyberCrime}
                alt="Appstore"
                className="px-3 py-5 h-24 w-20 mx-auto"
              />
              <p
                className={`text-center rounded-b-xl p-2 text-xs font-bold ${classes.textColor}`}
              >
                CYBER CRIMES
              </p>
            </div>
          </div>
        </div>
        <div className={classes.sectionbg}>
          <div className="flex items-center justify-center mx-auto container p-20">
            <img src={aboveFooter} alt="Appstore" />
          </div>
        </div>
        <Footer />
      </section>
      <Modal
        open={openLanguage}
        handleClose={handleClose}
        onClose={onClose}
        head="Choose Your Language"
        data={language}
        img={modalTab1}
        isImage={true}
        type="language"
      />
      <Modal
        open={openProvince}
        handleClose={handleClose}
        onClose={onClose}
        head="Choose Your Province"
        data={province}
        img={modalTab2}
        isImage={false}
        type="province"
      />
      <Modal
        open={openDistrict}
        handleClose={handleClose}
        onClose={onClose}
        head="Choose Your District"
        data={district}
        img={modalTab3}
        isImage={false}
        type="district"
      />
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClickClose}
        aria-describedby="alert-dialog-slide-description"
        fullWidth
        maxWidth="xl"
      >
        <div className={`${classes.mHeigth}`}>{componentToRender()}</div>
      </Dialog>
    </div>
  );
};

export default HomePage;
