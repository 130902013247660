import React, { useState } from "react";
import Header from "../components/Header";
import emergency from "../assets/img/HomePage/emergency.svg";
import referral from "../assets/img/HomePage/referral.svg";
import guidance from "../assets/img/HomePage/guidance.svg";
import laws from "../assets/img/HomePage/laws.svg";
import Footer from "../components/Footer";
import ModalAbout from "../components/ModalAbout";
import { makeStyles } from "@mui/styles";
import { createTheme } from "@mui/material";
import Options from "../components/Options";
import { Link } from "react-router-dom";

const theme = createTheme();

const useStyles = makeStyles((theme) => ({
  bgcolor: {
    background: "#FFFFFF",
  },
  textColor: {
    color: theme.palette.primary.main,
  },

  sectionbg: {
    background: theme.palette.primary.main,
  },

  sectionBgLight: {
    backgroundColor: "#F6F1F4",
  },

  emergencyBg: {
    backgroundColor: theme.palette.emergency.light,
  },

  emergencyTextBg: {
    backgroundColor: theme.palette.emergency.main,
  },
  referralBg: {
    backgroundColor: theme.palette.referral.light,
  },

  referralTextBg: {
    backgroundColor: theme.palette.referral.main,
  },
  guidanceBg: {
    backgroundColor: theme.palette.guidance.light,
  },

  guidanceTextBg: {
    backgroundColor: theme.palette.guidance.main,
  },
  lawsBg: {
    backgroundColor: theme.palette.laws.light,
  },

  lawsTextBg: {
    backgroundColor: theme.palette.laws.main,
  },
  fullScreen: {
    minHeight: "100vh",
  },
}));

const HumQadamHome = () => {
  const classes = useStyles();
  const [openAbout, setOpenAbout] = useState(false);
  const handleOpen = () => setOpenAbout(true);
  const handleClose = () => {
    setOpenAbout(false);
  };

  const data = [
    {
      id: 1,
      text: "About",
    },
    {
      id: 2,
      text: "Partners",
    },
    {
      id: 3,
      text: "Privacy Policy",
    },
    {
      id: 4,
      text: "Terms and Conditions",
    },
  ];
  return (
    <div
      className={`${classes.sectionBgLight} ${classes.fullScreen} flex flex-col justify-between`}
    >
      <div>
        <Header />
        <Options handleOpen={handleOpen} />
        <div className="px-5 pb-16">
          <div className="flex flex-col sm:flex-row justify-center items-center">
            <Link
              to={"/emergency-services"}
              className={`m-2 w-36 flex-col cursor-pointer ${classes.emergencyBg} rounded-xl`}
            >
              <div>
                <img
                  src={emergency}
                  alt="Appstore"
                  className="px-3 py-5 w-20 mx-auto"
                />
              </div>
              <p
                className={`${classes.emergencyTextBg} text-center rounded-b-xl p-2 text-xs text-white`}
              >
                Emergency Services
              </p>
            </Link>
            <div
              className={`m-2 w-36 flex-col ${classes.referralBg} rounded-xl`}
            >
              <div>
                <img
                  src={referral}
                  alt="Appstore"
                  className="px-3 py-5 w-20 mx-auto"
                />
              </div>
              <p
                className={`${classes.referralTextBg} text-center rounded-b-xl p-2 text-xs text-white`}
              >
                Referral Directory
              </p>
            </div>
          </div>
          <div className="flex flex-col sm:flex-row justify-center items-center">
            <div
              className={`m-2 w-36 flex-col ${classes.guidanceBg} rounded-xl`}
            >
              <div>
                <img
                  src={guidance}
                  alt="Appstore"
                  className="px-3 py-5 h-24 mx-auto"
                />
              </div>
              <p
                className={`${classes.guidanceTextBg} text-center rounded-b-xl py-2 text-xs text-white`}
              >
                Step by Step Guidance
              </p>
            </div>
            <div className={`m-2 w-36 flex-col ${classes.lawsBg} rounded-xl`}>
              <div>
                <img
                  src={laws}
                  alt="Appstore"
                  className="px-3 py-5 h-24 mx-auto"
                />
              </div>
              <p
                className={`${classes.lawsTextBg} text-center rounded-b-xl p-2 text-xs text-white`}
              >
                Relevant Laws
              </p>
            </div>
          </div>
        </div>
      </div>
      <div>
        <Footer />
        <ModalAbout open={openAbout} handleClose={handleClose} />
      </div>
    </div>
  );
};

export default HumQadamHome;
