/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import logo from "../assets/img/HomePage/humqadamLogo.svg";
import shirkat from "../assets/img/HomePage/shirkatGahLogo.svg";
import { Link } from "react-router-dom";
import { createTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import { changeTab } from "../redux/TabSlice";
import { toast } from "react-toastify";

const theme = createTheme();

const useStyles = makeStyles((theme) => ({
  hamburger: {
    "&hover": { background: theme.palette.primary.main },
  },
  textColor: {
    color: theme.palette.primary.main,
  },
}));

const Header = ({ main }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const { selDistrictId } = useSelector((state) => state.district);
  const [list, setList] = useState([
    {
      id: 2,
      text: "Who We Are",
      url: "https://shirkatgah.org/team/",
    },
    {
      id: 3,
      text: "ShirkatGah",
      url: "https://shirkatgah.org/",
    },
    {
      id: 4,
      text: "Contact",
      url: "https://shirkatgah.org/contact/",
    },
  ]);

  const handleClick = (tab) => {
    if (!main && !selDistrictId) {
      toast.error("Select District");
      return;
    }
    dispatch(changeTab(tab));
  };

  return (
    <div>
      <nav className="bg-white border-gray-200 bg-color">
        <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
          <a
            onClick={() => handleClick("HumQadam")}
            className="flex items-center cursor-pointer"
          >
            <img src={logo} className="h-12 mr-3" alt="HumQadam Logo" />
          </a>
          <div className="flex md:order-2">
            <a href="/" className="flex items-center">
              <img src={shirkat} className="h-12 mr-3" alt="ShirkatGah Logo" />
            </a>
            <button
              onClick={() => setIsOpen(!isOpen)}
              data-collapse-toggle="navbar-cta"
              type="button"
              className={`inline-flex ${classes.hamburger} items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400`}
              aria-controls="navbar-cta"
              aria-expanded="false"
            >
              <span className="sr-only">Open main menu</span>
              <svg
                className="w-5 h-5"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 17 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M1 1h15M1 7h15M1 13h15"
                />
              </svg>
            </button>
          </div>
          <div
            className={`${
              isOpen ? "block" : "hidden"
            }  items-center justify-between w-full md:flex md:w-auto md:order-1`}
            id="navbar-cta"
          >
            <ul className="flex flex-col font-medium p-4 md:p-0 mt-4 rounded-lg md:flex-row md:space-x-8 md:mt-0 md:border-0 ">
              <li>
                <a
                  href="#"
                  onClick={() => handleClick("HumQadam")}
                  className={`block py-2 pl-3 pr-4 ${classes.textColor}`}
                  aria-current="page"
                  rel="noreferrer"
                >
                  Home
                </a>
              </li>
              {list.map((lis) => (
                <li key={lis.id}>
                  <a
                    href={lis.url}
                    target="_blank"
                    className={`block py-2 pl-3 pr-4 ${classes.textColor}`}
                    aria-current="page"
                    rel="noreferrer"
                  >
                    {lis.text}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Header;
