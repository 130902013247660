import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
import { getTranlation, getTranlationArr } from "../helpers/utils";

export const getLaws = createAsyncThunk(
  "OffenceSlice/getLaws",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_LOCAL_API}/laws`
      );
      return {
        res: response.data.data["Relevant Laws"],
        langId: data.langId,
      };
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.response.data);
    }
  }
);

export const getSelectedLaw = createAsyncThunk(
  "OffenceSlice/getSelectedLaw",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_LOCAL_API}/laws/${data.id}`
      );
      return {
        res: response.data.data["Relevant Law"],
        langId: data.langId,
      };
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.response.data);
    }
  }
);

export const getGuidance = createAsyncThunk(
  "OffenceSlice/getGuidance",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_LOCAL_API}/guidance-step/${data.district}`
      );
      return {
        res: response.data.data["Guidance Step"],
        langId: data.langId,
      };
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.response.data);
    }
  }
);

export const getSelectedGuidance = createAsyncThunk(
  "OffenceSlice/getSelectedGuidance",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_LOCAL_API}/one-guidance-step/${data.id}/${data.district}`
      );
      return {
        res: response.data.data["Guidance Step"],
        langId: data.langId,
      };
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.response.data);
    }
  }
);

export const OffenceSlice = createSlice({
  name: "offence",
  initialState: {
    offenceLaws: [],
    offenceGuidance: [],
    selectedLaw: null,
    selectedGuide: null,
  },
  reducers: {
    setSelectedLaw: (state, action) => {
      state.selectedLaw = action.payload;
    },
    setSelectedGuide: (state, action) => {
      state.selectedGuide = action.payload;
    },
    resetOffStates: (state, action) => {
      state.offenceLaws = [];
      state.offenceGuidance = [];
      state.selectedLaw = null;
      state.selectedGuide = {};
    },
  },
  extraReducers: {
    [getLaws.fulfilled]: (state, action) => {
      state.offenceLaws = action.payload.res.map((obj) => {
        let trans = { ...obj };
        trans.title = getTranlation(obj.title, action.payload.langId);
        trans.description = getTranlation(
          obj.description,
          action.payload.langId
        );
        // trans.laws = obj.laws.map((law) => {
        //   let transLaw = { ...law };
        //   transLaw.title = getTranlation(law.title, action.payload.langId);
        //   return transLaw;
        // });
        // trans.sections = getTranlationArr(obj.sections, action.payload.langId);
        return trans;
      });
    },
    [getLaws.rejected]: (state, action) => {
      toast.error(action.payload);
    },
    [getSelectedLaw.fulfilled]: (state, action) => {
      // console.log(action.payload.res);
      const law = action.payload.res.map((obj) => {
        let trans = { ...obj };
        trans.title = getTranlation(obj?.title, action.payload.langId);
        trans.description = getTranlation(
          obj?.description,
          action.payload.langId
        );
        trans.laws = obj?.laws.map((law) => {
          let transLaw = { ...law };
          transLaw.title = getTranlation(law?.title, action.payload.langId);
          return transLaw;
        });
        trans.sections = getTranlationArr(obj?.sections, action.payload.langId);
        return trans;
      });
      state.selectedLaw = law[0];
    },
    [getSelectedLaw.rejected]: (state, action) => {
      toast.error(action.payload);
    },
    [getGuidance.fulfilled]: (state, action) => {
      state.offenceGuidance = action.payload.res.map((obj) => {
        let trans = { ...obj };
        trans.title = getTranlation(obj.title, action.payload.langId);
        trans.description = getTranlation(
          obj.description,
          action.payload.langId
        );
        // trans.guidance_steps = obj.guidance_steps.map((step) => {
        //   let newStep = { ...step };
        //   newStep.title = getTranlation(step.title, action.payload.langId);
        //   newStep.guidance_step_description =
        //     step.guidance_step_description.map((stepDes) => {
        //       let desObj = { ...stepDes };
        //       desObj.description = getTranlation(
        //         stepDes.description,
        //         action.payload.langId
        //       );
        //       desObj.heading = getTranlation(
        //         stepDes.heading,
        //         action.payload.langId
        //       );
        //       return desObj;
        //     });
        //   return newStep;
        // });
        return trans;
      });
    },
    [getGuidance.rejected]: (state, action) => {
      toast.error(action.payload);
    },
    [getSelectedGuidance.fulfilled]: (state, action) => {
      const guides = action.payload.res.map((obj) => {
        let trans = { ...obj };
        trans.title = getTranlation(obj.title, action.payload.langId);
        trans.description = getTranlation(
          obj.description,
          action.payload.langId
        );
        trans.guidance_steps = obj.guidance_steps.map((step) => {
          let newStep = { ...step };
          newStep.title = getTranlation(step.title, action.payload.langId);
          newStep.guidance_step_description =
            step.guidance_step_description.map((stepDes) => {
              let desObj = { ...stepDes };
              desObj.description = getTranlation(
                stepDes.description,
                action.payload.langId
              );
              desObj.heading = getTranlation(
                stepDes.heading,
                action.payload.langId
              );
              return desObj;
            });
          return newStep;
        });
        return trans;
      });
      state.selectedGuide = guides[0];
    },
    [getSelectedGuidance.rejected]: (state, action) => {
      toast.error(action.payload);
    },
  },
});
export const { setSelectedLaw, setSelectedGuide, resetOffStates } =
  OffenceSlice.actions;

export default OffenceSlice.reducer;
