import { useEffect } from "react";
import HomePage from "./pages/HomePage";
import HumQadamHome from "./pages/HumQadamHome";
import EmergencyServices from "./pages/EmergencyServices";
import ServicesDetail from "./pages/ServicesDetail";
import { ThemeProvider, createTheme } from "@mui/material";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import store from "./redux/store";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import JameelNoori from "./assets/Font/JameelNoori.woff2";

import "react-toastify/dist/ReactToastify.css";
import PrivacyPolicy from "./pages/PrivacyPolicy";

const theme = createTheme({
  palette: {
    primary: {
      main: "#A94E76",
    },
    secondary: {
      main: "#D88284",
    },
    emergency: {
      main: "#D45558",
      light: "#D88284",
    },
    referral: {
      main: "#8E264E",
      light: "#A94E76",
    },
    guidance: {
      main: "#4E5B36",
      light: "#B2AE92",
    },
    laws: {
      main: "#816EAF",
      light: "#D4C7E2",
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 640,
      md: 768,
      lg: 1024,
      xl: 1280,
    },
  },
});

const router = createBrowserRouter([
  {
    path: "/",
    element: <HomePage />,
  },
  {
    path: "/humqadam",
    element: <HumQadamHome />,
  },
  {
    path: "/privacy-policy-humqadam",
    element: <PrivacyPolicy />,
  },
]);

function App() {
  useEffect(() => {
    let font = new FontFace("jameel-noori", `url(${JameelNoori})`);
    font.load();
  }, []);

  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <RouterProvider router={router} />
      </ThemeProvider>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </Provider>
  );
}

export default App;
