import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import { makeStyles } from "@mui/styles";
import Options from "../components/Options";
import ModalAbout from "../components/ModalAbout";
import { getTilte } from "../helpers/utils";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";
import ArrowBack from "../assets/img/HumQadamHome/arrowBack.svg";
import { changeTab } from "../redux/TabSlice";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

const useStyles = makeStyles((theme) => ({
  bgcolor: {
    background: "#FFFFFF",
  },
  textColor: {
    color: theme.palette.primary.main,
  },

  sectionbg: {
    background: theme.palette.primary.main,
  },
  sectionBgLight: {
    backgroundColor: "#F6F1F4",
  },
  fullScreen: {
    minHeight: "100vh",
  },
  input: {
    width: "500px",
    border: "1px solid #AE4971",
    display: "block",
    margin: "15px 0",
    "&::placeholder": {
      color: theme.palette.primary.main,
    },
    [theme.breakpoints.down("sm")]: {
      width: "300px",
    },
  },
}));

const Contact = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [openAbout, setOpenAbout] = useState(false);
  const { appContent } = useSelector((state) => state.appContent);
  const [submitTitle, setSubmitTitle] = useState("");
  const [nameTitle, setNameTitle] = useState("");
  const [emailTitle, setEmailTitle] = useState("");
  const [addressTitle, setAddressTitle] = useState("");
  const [messageTitle, setMessageTitle] = useState("");
  const [contactTitle, setContactTitle] = useState("");
  const [subjectTitle, setSubjectTitle] = useState("");
  const [nameText, setNameText] = useState("");
  const [emailText, setEmailText] = useState("");
  const [addressText, setAddressText] = useState("");
  const [messageText, setMessageText] = useState("");
  const [subjectText, setSubjectText] = useState("");
  const [tabSelect, setTabSelect] = useState("Contact");
  const [reportMissingTitle, setReportMissingTitle] = useState("");
  const { backFromSettings } = useSelector((state) => state.page);
  const [reportProblemTitle, setReportProblemTitle] = useState("");
  const [generalTitle, setGeneralTitle] = useState("");
  const { isEnglish } = useSelector((state) => state.language);
  const handleOpen = () => setOpenAbout(true);
  const handleClose = () => {
    setOpenAbout(false);
  };

  useEffect(() => {
    if (appContent) {
      setAddressTitle(getTilte(appContent, "Address"));
      setContactTitle(getTilte(appContent, "Contact"));
      setMessageTitle(getTilte(appContent, "Message"));
      setNameTitle(getTilte(appContent, "Name"));
      setEmailTitle(getTilte(appContent, "Email"));
      setSubjectTitle(getTilte(appContent, "Subject"));
      setSubjectText(getTilte(appContent, "Subject"));
      setSubmitTitle(getTilte(appContent, "Submit"));
      setReportMissingTitle(getTilte(appContent, "Report Missing Services"));
      setReportProblemTitle(getTilte(appContent, "Report a Problem"));
      setGeneralTitle(getTilte(appContent, "General Enquiries"));
    }
  }, [appContent]);

  const handleSubmit = async () => {
    // Handle form submission logic here
    console.log("Name:", nameText);
    console.log("Email:", emailText);
    console.log("Address:", addressText);
    console.log("Message:", messageText);
    console.log("Selected Option:", subjectText);
    console.log(process.env.REACT_APP_LOCAL_API);
    try {
      await axios.post(`${process.env.REACT_APP_LOCAL_API}/contact-us-email`, {
        from: emailText,
        subject: subjectText,
        name: nameText,
        address: addressText,
        message: messageText,
      });
      toast.success("Email sent successfully");
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data ?? "Internal server error");
    }
  };

  useEffect(() => {
    dispatch(changeTab(tabSelect));
  }, [tabSelect]);

  return (
    <div className={`${classes.sectionBgLight} flex flex-col justify-between`}>
      <div>
        <Header />
        <Options handleOpen={handleOpen} />
        <div className="px-5 mt-2 pb-10">
          <h6
            className={`text-center mb-8 text-2xl ${classes.textColor} font-bold`}
          >
            {contactTitle}
          </h6>
          <div className="flex flex-col sm:flex-row justify-center items-center ">
            <div className={`flex flex-col items-center`}>
              <input
                type="text"
                value={nameText}
                onChange={(e) => setNameText(e.target.value)}
                placeholder={nameTitle}
                className={`bg-white px-4 py-2 ${
                  isEnglish ? "text-left" : "text-right"
                } ${classes.textColor} ${classes.input} rounded-xl`}
              />
              <input
                type="email"
                value={emailText}
                onChange={(e) => setEmailText(e.target.value)}
                placeholder={emailTitle}
                className={`bg-white px-4 py-2 ${
                  isEnglish ? "text-left" : "text-right"
                } ${classes.textColor} ${classes.input} rounded-xl`}
              />
              <input
                type="text"
                placeholder={addressTitle}
                value={addressText}
                onChange={(e) => setAddressText(e.target.value)}
                className={`bg-white px-4 py-2 ${
                  isEnglish ? "text-left" : "text-right"
                }  ${classes.textColor} ${classes.input} rounded-xl`}
              />
              {/* <select
                onChange={(e) => {
                  setSubjectText(e.target.value);
                }}
                className={`bg-white px-2 py-6 ${
                  isEnglish ? "text-left" : "text-right text-sm"
                }  ${classes.textColor} ${classes.input} rounded-xl`}
              >
                <option value={subjectTitle}>{subjectTitle}</option>
                <option value={reportMissingTitle}>{reportMissingTitle}</option>
                <option value={reportProblemTitle}>{reportProblemTitle}</option>
                <option value={generalTitle}>{generalTitle}</option>
              </select> */}
              <FormControl fullWidth>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={subjectText}
                  className={`bg-white ${
                    isEnglish ? "text-left" : "text-right text-sm"
                  }  ${classes.textColor} ${classes.input} rounded-xl`}
                  style={{
                    fontFamily: "Roboto,jameel-noori",
                    color: "#A94E76",
                  }}
                  onChange={(e) => {
                    setSubjectText(e.target.value);
                  }}
                >
                  <MenuItem
                    style={{
                      fontFamily: "Roboto,jameel-noori",
                      color: "#A94E76",
                    }}
                    value={subjectTitle}
                  >
                    {subjectTitle}
                  </MenuItem>
                  <MenuItem
                    style={{
                      fontFamily: "Roboto,jameel-noori",
                      color: "#A94E76",
                    }}
                    value={reportMissingTitle}
                  >
                    {reportMissingTitle}
                  </MenuItem>
                  <MenuItem
                    style={{
                      fontFamily: "Roboto,jameel-noori",
                      color: "#A94E76",
                    }}
                    value={reportProblemTitle}
                  >
                    {reportProblemTitle}
                  </MenuItem>
                  <MenuItem
                    style={{
                      fontFamily: "Roboto,jameel-noori",
                      color: "#A94E76",
                    }}
                    value={generalTitle}
                  >
                    {generalTitle}
                  </MenuItem>
                </Select>
              </FormControl>
              <textarea
                type="text"
                placeholder={messageTitle}
                value={messageText}
                onChange={(e) => setMessageText(e.target.value)}
                className={`bg-white h-32 px-4 py-2 ${
                  isEnglish ? "text-left" : "text-right"
                }  ${classes.textColor} ${classes.input} rounded-xl`}
              />
              <input
                value={submitTitle}
                onClick={handleSubmit}
                type="submit"
                className={`${classes.sectionbg} px-8 py-2 text-white rounded-xl`}
              />
            </div>
          </div>
          <img
            alt="Arrow Back"
            onClick={() => {
              if (backFromSettings === "Contact") setTabSelect("HumQadam");
              else setTabSelect(backFromSettings);
            }}
            src={ArrowBack}
            className="mt-16 cursor-pointer self-start ml-5"
          />
        </div>
      </div>
      <div>
        <ModalAbout open={openAbout} handleClose={handleClose} />
      </div>
    </div>
  );
};

export default Contact;
