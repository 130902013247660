import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import emergency from "../assets/img/HomePage/emergency.svg";
import ModalAbout from "../components/ModalAbout";
import { makeStyles } from "@mui/styles";
import { Typography } from "@mui/material";
import Options from "../components/Options";
import Guard from "../assets/img/EmergencyServices/securityguard.png";
import Heart from "../assets/img/EmergencyServices/heart.svg";
import { changeTab } from "../redux/TabSlice";
import { useDispatch, useSelector } from "react-redux";
import { getTilte } from "../helpers/utils";
import { setSelectedService } from "../redux/AppContentSlice";
import ArrowBack from "../assets/img/HumQadamHome/arrowBack.svg";

// const theme = createTheme();

const useStyles = makeStyles((theme) => ({
  bgcolor: {
    background: "#FFFFFF",
  },
  textColor: {
    color: theme.palette.primary.main,
  },

  sectionbg: {
    background: theme.palette.primary.main,
  },

  sectionBgLight: {
    backgroundColor: "#F6F1F4",
  },

  emergencyBg: {
    backgroundColor: theme.palette.emergency.light,
  },

  emergencyTextBg: {
    backgroundColor: theme.palette.emergency.main,
  },
}));

function EmergencyServices() {
  const classes = useStyles();
  const [openAbout, setOpenAbout] = useState(false);
  const { appContent } = useSelector((state) => state.appContent);
  const [tabSelect, setTabSelect] = useState("EmergencyServices");
  const [emergencyTitle, setEmergencyTitle] = useState("");
  const [policeTitle, setPoliceTitle] = useState("");
  const [medicalTitle, setMedicalTitle] = useState("");
  const [emergencyDes, setEmergencyDes] = useState("");
  const dispatch = useDispatch();
  const handleOpen = () => setOpenAbout(true);
  const handleClose = () => {
    setOpenAbout(false);
  };

  useEffect(() => {
    if (appContent) {
      setEmergencyTitle(getTilte(appContent, "Emergency Services"));
      setPoliceTitle(getTilte(appContent, "Police Services"));
      setMedicalTitle(getTilte(appContent, "Medical Services"));
      setEmergencyDes(getTilte(appContent, "Emergency Description"));
    }
  }, [appContent]);

  useEffect(() => {
    dispatch(changeTab(tabSelect));
  }, [tabSelect]);

  const data = [
    {
      id: 1,
      text: "About",
    },
    {
      id: 2,
      text: "Partners",
    },
    {
      id: 3,
      text: "Privacy Policy",
    },
    {
      id: 4,
      text: "Terms and Conditions",
    },
  ];
  return (
    <div
      className={`${classes.emergencyBg} ${classes.fullScreen} flex flex-col justify-between`}
    >
      <Header />
      <Options handleOpen={handleOpen} />
      <div className="flex flex-col justify-center content-center items-center mb-36">
        <img className=" w-12 mb-2" src={emergency} alt="Emergency" />
        <Typography color={"white"} variant="p" className="mb-3 text-2xl">
          {emergencyTitle}
        </Typography>
        <Typography variant="p" color={"white"} className="md:w-96 text-center">
          {emergencyDes}
        </Typography>
        <div className="mt-5 mb-10 flex flex-col md:flex-row">
          <div
            onClick={() => {
              dispatch(setSelectedService(medicalTitle));
              setTabSelect("MedicalServices");
            }}
            className="cursor-pointer m-2 border-2 border-white p-8 pb-2 rounded-3xl"
          >
            <img className="h-28 mb-5" src={Heart} alt="Guard" />
            <Typography
              color={"white"}
              variant="p"
              className="block text-center font-bold mt-2"
            >
              {medicalTitle}
            </Typography>
          </div>
          <div
            onClick={() => {
              dispatch(setSelectedService(policeTitle));
              setTabSelect("PoliceServices");
            }}
            className="cursor-pointer m-2 border-2 border-white p-8 pb-2 rounded-3xl"
          >
            <img className="h-28 mb-5" src={Guard} alt="Guard" />
            <Typography
              variant="p"
              color={"white"}
              className="block text-center font-bold mt-2"
            >
              {policeTitle}
            </Typography>
          </div>
        </div>
        <img
          alt="Arrow Back"
          onClick={() => setTabSelect("HomePage")}
          src={ArrowBack}
          className="mt-16 cursor-pointer self-start ml-5"
        />
      </div>
      <div>
        <ModalAbout open={openAbout} handleClose={handleClose} />
      </div>
    </div>
  );
}

export default EmergencyServices;
