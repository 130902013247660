import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import referral from "../assets/img/HomePage/referral.svg";
import ModalAbout from "../components/ModalAbout";
import { makeStyles, styled } from "@mui/styles";
import { Paper, Typography } from "@mui/material";
import Options from "../components/Options";
import organization from "../assets/img/Referral/organization.svg";
import map from "../assets/img/EmergencyServices/map.svg";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import { changeTab } from "../redux/TabSlice";
import { getTilte } from "../helpers/utils";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useTheme } from "@emotion/react";
import callLeft from "../assets/img/EmergencyServices/callLeft.svg";
import callRight from "../assets/img/EmergencyServices/callRight.svg";
import ArrowBack from "../assets/img/HumQadamHome/arrowBack.svg";

// const theme = createTheme();

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const useStyles = makeStyles((theme) => ({
  bgcolor: {
    background: "#FFFFFF",
  },
  textColor: {
    color: theme.palette.referral.main,
  },
  input: {
    "&::placeholder": {
      color: theme.palette.referral.main,
    },
  },

  sectionbg: {
    background: theme.palette.primary.main,
  },

  sectionBgLight: {
    backgroundColor: "#FCEFF7",
  },

  referralBg: {
    backgroundColor: theme.palette.referral.light,
  },

  emergencyTextBg: {
    backgroundColor: theme.palette.referral.main,
  },

  fullScreen: {
    minHeight: "100vh",
  },
  height: {
    maxHeight: "50vh",
  },
}));

function HelplineDetails({ title }) {
  const theme = useTheme();
  const classes = useStyles();
  const { tab } = useSelector((state) => state.tab);
  const { isEnglish } = useSelector((state) => state.language);
  const [openAbout, setOpenAbout] = useState(false);
  const [openItem, setOpenItem] = useState(false);
  const [showTitle, setShowTitle] = useState();
  const { appContent, selectedProvider, selectedProviderEng } = useSelector(
    (state) => state.appContent
  );
  const handleOpen = () => setOpenAbout(true);
  const { selectedItem } = useSelector((state) => state.organizations);
  const { backToGuide } = useSelector((state) => state.page);
  const [referralTitle, setReferralTitle] = useState("");
  const [description, setDescription] = React.useState();
  const [hours, setHours] = React.useState();
  const handleClose = () => setOpenAbout(false);
  const [url, setUrl] = useState();

  const handleCloseItem = () => setOpenItem(false);
  const [tabSelect, setTabSelect] = useState("HelplineDetails");
  const dispatch = useDispatch();

  useEffect(() => {
    if (appContent) {
      setReferralTitle(getTilte(appContent, "Referral Directory"));
    }
  }, [appContent]);

  useEffect(() => {
    dispatch(changeTab(tabSelect));
  }, [tabSelect]);

  const data = [
    {
      id: 1,
      text: "About",
    },
    {
      id: 2,
      text: "Partners",
    },
    {
      id: 3,
      text: "Privacy Policy",
    },
    {
      id: 4,
      text: "Terms and Conditions",
    },
  ];

  useEffect(() => {
    if (appContent) {
      setReferralTitle(getTilte(appContent, "Referral Directory"));
    }
  }, [appContent]);

  React.useEffect(() => {
    const createDes = (str) => {
      if (typeof str !== "string") return;

      let data = str.split(":");
      setDescription(data[0]);
      setHours(data[1]);
    };
    createDes(selectedItem?.description);
  }, [selectedItem]);

  const openMap = (url) => {
    const destination = encodeURIComponent(`${url}`);
    const link = `http://maps.google.com/?daddr=${destination}`;
    console.log(url);
    console.log(link);
    setUrl(link);
  };

  if (!selectedItem) return;

  return (
    <div
      className={`${classes.sectionBgLight} pb-20 flex flex-col justify-between`}
    >
      <div>
        <Header />
        <div className="flex-col md:flex-row flex justify-between">
          <div className=" block md:hidden mb-5">
            <Options handleOpen={handleOpen} />
          </div>
          <div className="flex mx-5 mt-2 content-center items-center">
            <img className="w-6 mr-3 mb-2" src={referral} alt="Emergency" />
            <div className="flex flex-col md:flex-row">
              <Typography
                onClick={() => setTabSelect("ReferralDirectory")}
                className="mr-3 cursor-pointer"
                color={"black"}
                variant="p"
              >
                {referralTitle} {">"}
              </Typography>
              <Typography
                onClick={() => setTabSelect(selectedProviderEng)}
                className="mr-3 cursor-pointer"
                color={"black"}
                variant="p"
              >
                {selectedProvider} {">"}
              </Typography>
              <Typography className="font-bold" color={"black"} variant="p">
                {selectedItem?.title}
              </Typography>
            </div>
          </div>
          <div className=" hidden md:block">
            <Options handleOpen={handleOpen} />
          </div>
        </div>

        <div className="flex flex-col justify-center content-center items-center my-2">
          <div className={clsx("flex-col lg:flex-row flex px-10 my-5")}>
            <div
              className={clsx(
                classes.referralBg,
                "md:w-96 w-64 text-white p-3 lg:m-3 m-0 rounded-xl"
              )}
            >
              <Typography
                className={`block ${isEnglish ? "text-left" : "text-right"}`}
                variant="p"
                style={{ whiteSpace: "pre-wrap" }}
              >
                {description}
              </Typography>
              <Typography
                className={`block mb-16 ${
                  isEnglish ? "text-left" : "text-right"
                }`}
                variant="p"
              >
                {hours}
              </Typography>
            </div>
            <div>
              <div
                className={clsx(
                  "p-3 md:w-96 w-64  my-3 rounded-xl flex justify-center items-center",
                  classes.referralBg,
                  isEnglish ? "flex-row" : "flex-row-reverse"
                )}
              >
                <img className="w-9 mx-2" src={organization} alt="Map" />
                <Typography variant="p" className="mt-2" color={"white"}>
                  {selectedItem?.organizational_status}
                </Typography>
              </div>
              <div
                className={clsx("p-3 rounded-xl", classes.referralBg)}
                onClick={() => setOpenItem(true)}
              >
                {selectedItem?.contact.map((con) => (
                  <div
                    key={con._id}
                    className={clsx(
                      "flex p-1 rounded-3xl items-center",
                      classes.sectionbg,
                      isEnglish ? "flex-row" : "flex-row-reverse"
                    )}
                  >
                    <img
                      className="w-5 h-5"
                      src={isEnglish ? callLeft : callRight}
                      alt="call"
                    />
                    <Typography variant="p" color={"white"} className="mx-3">
                      {con}
                    </Typography>
                  </div>
                ))}
                {selectedItem?.address?.length > 0 && (
                  <div
                    className={clsx(
                      "flex justify-between items-center my-3 ",
                      isEnglish ? "flex-row" : "flex-row-reverse"
                    )}
                  >
                    <div className="flex flex-col w-40 md:w-64 ">
                      <Typography
                        variant="p"
                        color={"white"}
                        className={isEnglish ? "text-left" : "text-right"}
                      >
                        {selectedItem?.address[0]}
                      </Typography>
                    </div>
                    <a
                      href={url}
                      target="_blank"
                      className="flex"
                      rel="noreferrer"
                    >
                      <img
                        onClick={() => openMap(selectedItem?.address[0])}
                        className="w-9 h-9 mr-3 cursor-pointer"
                        src={map}
                        alt="Map"
                      />
                    </a>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <img
        alt="Arrow Back"
        onClick={() => {
          if (backToGuide) setTabSelect("GuideStep");
          else setTabSelect(selectedProviderEng);
        }}
        src={ArrowBack}
        className="mt-16 cursor-pointer self-start ml-5"
      />
      <div>
        <ModalAbout open={openAbout} handleClose={handleClose} />
      </div>
    </div>
  );
}

export default HelplineDetails;
