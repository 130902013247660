import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import call from "../assets/img/EmergencyServices/call.svg";
import callLeft from "../assets/img/EmergencyServices/callLeft.svg";
import callRight from "../assets/img/EmergencyServices/callRight.svg";
import { makeStyles } from "@mui/styles";
import { Typography } from "@mui/material";
import clsx from "clsx";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  bgcolor: {
    background: "#FFFFFF",
  },
  textColor: {
    color: theme.palette.emergency.main,
  },
  sectionbg: {
    background: theme.palette.emergency.light,
  },
  medWidth: {
    [theme.breakpoints.up("md")]: {
      width: "450px !important",
    },
  },
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "#F6F1F4",
  p: 4,
};

export default function ModalAbout({ open, handleClose, item }) {
  const classes = useStyles();
  const { isEnglish } = useSelector((state) => state.language);
  const [description, setDescription] = React.useState();
  const [hours, setHours] = React.useState();
  React.useEffect(() => {
    const createDes = (str) => {
      if (typeof str !== "string") return;

      let data = str.split(":");
      setDescription(data[0]);
      setHours(data[1]);
    };
    createDes(item?.description);
  }, [item]);

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          className={`border-2 border-red-100 rounded-2xl w-72 focus:outline-none ${classes.medWidth}`}
          sx={style}
        >
          <h6 className={`text-center mb-6 ${classes.textColor} font-bold`}>
            {item?.title}
          </h6>
          <div className={clsx(" p-5 rounded-3xl", classes.sectionbg)}>
            <Typography
              className={`mt-3 block ${isEnglish ? "text-left" : "text-right"}`}
              variant="p"
              style={{ whiteSpace: "pre-wrap" }}
              color={"white"}
            >
              {description}
            </Typography>
            <Typography
              className={`mt-3 block ${isEnglish ? "text-left" : "text-right"}`}
              variant="p"
              color={"white"}
            >
              {hours}
            </Typography>
          </div>
          {item?.contact.map((con) => (
            <div
              key={con._id}
              className={clsx(
                "flex p-5 mt-5 rounded-3xl items-center",
                classes.sectionbg,
                isEnglish ? "flex-row" : "flex-row-reverse"
              )}
            >
              <img
                className="w-5 h-5"
                src={isEnglish ? callLeft : callRight}
                alt="call"
              />
              <Typography variant="p" color={"white"} className="mx-3">
                {con}
              </Typography>
            </div>
          ))}
        </Box>
      </Modal>
    </div>
  );
}
