import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import { makeStyles } from "@mui/styles";
import { createTheme } from "@mui/material";
import Options from "../components/Options";
import ModalAbout from "../components/ModalAbout";
import { useDispatch, useSelector } from "react-redux";
import { setProvinceId } from "../redux/ProvinceSlice";
import {
  setDistrict,
  setDistrictId,
  setProvDistrict,
} from "../redux/DistrictSlice";
import { setLanguageId } from "../redux/LanguageSlice";
import { resetOffStates } from "../redux/OffenceSlice";
import { resetOrgStates } from "../redux/OrganizationSlice";
import { getTilte } from "../helpers/utils";
import ArrowBack from "../assets/img/HumQadamHome/arrowBack.svg";
import { changeTab } from "../redux/TabSlice";
import { toast } from "react-toastify";
import clsx from "clsx";

const theme = createTheme();

const useStyles = makeStyles((theme) => ({
  bgcolor: {
    backgroundColor: "#FFFFFF",
  },
  textColor: {
    color: theme.palette.primary.main,
  },

  sectionbg: {
    background: theme.palette.primary.main,
  },
  sectionOrange: {
    background: "#D88284",
  },
  sectionBgLight: {
    backgroundColor: "#F6F1F4",
  },
  fullScreen: {
    minHeight: "100vh",
  },
}));

const Settings = () => {
  const classes = useStyles();
  const { language, selLanguageId, isEnglish } = useSelector(
    (state) => state.language
  );
  const { district, provDistrict, selDistrictId } = useSelector(
    (state) => state.district
  );
  const { province, federalId, selProvinceId } = useSelector(
    (state) => state.province
  );
  const { appContent } = useSelector((state) => state.appContent);
  const { backFromSettings } = useSelector((state) => state.page);
  const dispatch = useDispatch();
  const [openAbout, setOpenAbout] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(false);
  const [openDis, setOpenDis] = useState(false);
  const [lan, setLan] = useState();
  const [settingsTitle, setSettingsTitle] = useState("");
  const [languageTitle, setLanguageTitle] = useState("");
  const [chLocTitle, setChLocTitle] = useState("");
  const [chDisTitle, setChDisTitle] = useState("");
  const [tabSelect, setTabSelect] = useState("Settings");
  const handleOpen = () => setOpenAbout(true);
  const handleClose = () => {
    setOpenAbout(false);
  };

  useEffect(() => {
    if (appContent) {
      setSettingsTitle(getTilte(appContent, "Settings"));
      setLanguageTitle(getTilte(appContent, "Language"));
      setChLocTitle(getTilte(appContent, "Change Location"));
      setChDisTitle(getTilte(appContent, "Choose your District"));
    }
  }, [appContent]);

  useEffect(() => {
    dispatch(changeTab(tabSelect));
  }, [tabSelect]);

  useEffect(() => {
    if (language) {
      language.forEach((lan) => {
        if (lan._id === selLanguageId) {
          setLan(lan?.language);
          return;
        }
      });
    }
  }, [selLanguageId, language]);

  useEffect(() => {
    dispatch(resetOffStates());
  }, []);

  const handleChangeLang = (id) => {
    dispatch(setLanguageId(id));
    setOpenDropdown(false);
    dispatch(resetOffStates());
    dispatch(resetOrgStates());
  };

  const handleChangeProv = (item) => {
    setOpenDis(true);
    dispatch(setProvDistrict(item.districts));
    dispatch(setProvinceId(item._id));
    dispatch(setDistrictId(""));
  };

  const data = [
    {
      id: 1,
      text: "Punjab",
    },
    {
      id: 2,
      text: "Balochistan",
    },
    {
      id: 3,
      text: "Khyber Pakhtunkhwa",
    },
    {
      id: 4,
      text: "Sindh",
    },
  ];

  return (
    <div
      onClick={() => setOpenDropdown(false)}
      className={`${classes.sectionBgLight} flex flex-col justify-between`}
    >
      <div>
        <Header />
        <Options handleOpen={handleOpen} />
        <div className="px-5 mt-2 pb-52">
          <div className="flex flex-col justify-center items-center">
            <h6
              className={`text-center mb-8 text-2xl ${classes.textColor} font-bold`}
            >
              {settingsTitle}
            </h6>
            <h6 className={`text-center ${classes.textColor} font-bold`}>
              {languageTitle}
            </h6>
            <div onClick={(e) => e.stopPropagation()} className="mt-3">
              <div
                onClick={() => setOpenDropdown(!openDropdown)}
                className={`px-5 py-1 w-60 md:w-96 cursor-pointer rounded-3xl text-left ${classes.textColor} text-sm ${classes.bgcolor} `}
              >
                {lan}
              </div>
              {openDropdown && (
                <div
                  className={`bg-white p-4 mt-1 rounded-xl ${classes.textColor} text-sm absolute w-60 md:w-96`}
                >
                  <ul>
                    {language.map((lan) => (
                      <li
                        key={lan._id}
                        onClick={() => {
                          handleChangeLang(lan._id);
                        }}
                        className={`pb-2 cursor-pointer`}
                      >
                        {lan.language}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
            <h6
              className={`text-center my-3 mt-4 ${classes.textColor} font-bold`}
            >
              {chLocTitle}
            </h6>
            {province.map((item) => (
              <div key={item._id}>
                {item._id !== federalId && (
                  <button
                    className={clsx(
                      `md:w-96 w-60 block mx-auto ${
                        isEnglish ? "text-left" : "text-right"
                      } ${
                        selProvinceId === item._id
                          ? classes.sectionOrange
                          : classes.sectionbg
                      } m-2 p-3 px-5 text-white text-left flex rounded-xl`,
                      isEnglish ? "" : "flex-row-reverse "
                    )}
                    onClick={() => handleChangeProv(item)}
                  >
                    {item.icon && (
                      <img
                        className="w-7 h-7 mx-3"
                        alt="icon"
                        src={item.icon}
                      />
                    )}
                    {item.name}
                  </button>
                )}
              </div>
            ))}
            {openDis && (
              <>
                <h6
                  className={`text-center my-3 mt-4 ${classes.textColor} font-bold`}
                >
                  {chDisTitle}
                </h6>
                {district.map((item) => (
                  <button
                    className={clsx(
                      `md:w-96 w-60 block mx-auto ${
                        isEnglish ? "text-left" : "text-right"
                      } ${
                        selDistrictId === item._id
                          ? classes.sectionOrange
                          : classes.sectionbg
                      } m-2 p-3 px-5 text-white text-left flex rounded-xl`,
                      isEnglish ? "" : "flex-row-reverse "
                    )}
                    key={item._id}
                    onClick={() => {
                      dispatch(setDistrictId(item._id));
                    }}
                  >
                    {item.icon && (
                      <img
                        className="w-7 h-7 mx-3"
                        alt="icon"
                        src={item.icon}
                      />
                    )}
                    {item.name}
                  </button>
                ))}
              </>
            )}
          </div>
          <img
            alt="Arrow Back"
            onClick={() => {
              if (!selDistrictId) {
                toast.error("Select District");
                return;
              }
              if (backFromSettings === "Settings") setTabSelect("HumQadam");
              else if (
                backFromSettings === "Helpline Details" ||
                backFromSettings === "Provincial Helplines" ||
                backFromSettings === "National Helplines" ||
                backFromSettings === "Service Providers" ||
                backFromSettings === "HelplineDetails"
              )
                setTabSelect("ReferralDirectory");
              else if (backFromSettings === "GuideStep")
                setTabSelect("StepGuide");
              else if (backFromSettings === "RelevantLaws")
                setTabSelect("Laws");
              else setTabSelect(backFromSettings);
            }}
            src={ArrowBack}
            className="mt-16 cursor-pointer self-start ml-5"
          />
        </div>
      </div>
      <div>
        <ModalAbout open={openAbout} handleClose={handleClose} />
      </div>
    </div>
  );
};

export default Settings;
