import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import { getTilte } from "../helpers/utils";
import { changeTab } from "../redux/TabSlice";
import { resetOffStates } from "../redux/OffenceSlice";
import { setLanguageId } from "../redux/LanguageSlice";
import { resetOrgStates } from "../redux/OrganizationSlice";
import { setDistrictId, setProvDistrict } from "../redux/DistrictSlice";
import { setProvinceId } from "../redux/ProvinceSlice";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  bgcolor: {
    backgroundColor: "#FFFFFF",
  },
  textColor: {
    color: theme.palette.primary.main,
  },

  sectionbg: {
    background: theme.palette.primary.main,
  },
  sectionOrange: {
    background: "#D88284",
  },
  sectionBgLight: {
    backgroundColor: "#F6F1F4",
  },
  fullScreen: {
    minHeight: "100vh",
  },
}));
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "#F6F1F4",
  p: 4,
};

export default function ModalSettings({ open, handleClose }) {
  const classes = useStyles();
  const { language, selLanguageId, isEnglish } = useSelector(
    (state) => state.language
  );
  const { district, selDistrictId } = useSelector((state) => state.district);
  const { province, federalId, selProvinceId } = useSelector(
    (state) => state.province
  );
  const { appContent } = useSelector((state) => state.appContent);
  const dispatch = useDispatch();
  const [openDis, setOpenDis] = useState(false);
  const [chLocTitle, setChLocTitle] = useState("");
  const [chDisTitle, setChDisTitle] = useState("");
  const { tab } = useSelector((state) => state.tab);

  useEffect(() => {
    if (appContent) {
      setChLocTitle(getTilte(appContent, "Change Location"));
      setChDisTitle(getTilte(appContent, "Choose your District"));
    }
  }, [appContent]);

  const handleChangeProv = (item) => {
    setOpenDis(true);
    dispatch(setProvDistrict(item.districts));
    dispatch(setProvinceId(item._id));
    dispatch(setDistrictId(""));
  };

  const handleChangeDistrict = (item) => {
    dispatch(resetOrgStates());
    dispatch(resetOffStates());
    dispatch(setDistrictId(item._id));
    if (
      tab === "HelplineDetails" ||
      tab === "National Helplines" ||
      tab === "Service Providers" ||
      tab === "Provincial Helplines"
    ) {
      dispatch(changeTab("ReferralDirectory"));
    } else if (tab === "GuideStep") {
      dispatch(changeTab("StepGuide"));
    }
    // handleClose();
  };
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          className={`border-2 border-red-100 rounded-2xl w-70 ${classes.medWidth}`}
          sx={style}
        >
          <h6
            className={`text-center my-3 mt-4 ${classes.textColor} font-bold`}
          >
            {chLocTitle}
          </h6>
          {province.map((item) => (
            <div key={item._id}>
              {item._id !== federalId && (
                <button
                  className={clsx(
                    `md:w-96 w-60 block mx-auto ${
                      isEnglish ? "text-left" : "text-right"
                    } ${
                      selProvinceId === item._id
                        ? classes.sectionOrange
                        : classes.sectionbg
                    } m-2 p-3 px-5 text-white text-left flex rounded-xl`,
                    isEnglish ? "" : "flex-row-reverse "
                  )}
                  onClick={() => handleChangeProv(item)}
                >
                  {item.icon && (
                    <img className="w-7 h-7 mx-3" alt="icon" src={item.icon} />
                  )}
                  {item.name}
                </button>
              )}
            </div>
          ))}
          {openDis && (
            <>
              <h6
                className={`text-center my-3 mt-4 ${classes.textColor} font-bold`}
              >
                {chDisTitle}
              </h6>
              {district.map((item) => (
                <button
                  className={clsx(
                    `md:w-96 w-60 block mx-auto ${
                      isEnglish ? "text-left" : "text-right"
                    } ${
                      selDistrictId === item._id
                        ? classes.sectionOrange
                        : classes.sectionbg
                    } m-2 p-3 px-5 text-white text-left flex rounded-xl`,
                    isEnglish ? "" : "flex-row-reverse "
                  )}
                  key={item._id}
                  onClick={() => handleChangeDistrict(item)}
                >
                  {item.icon && (
                    <img className="w-7 h-7 mx-3" alt="icon" src={item.icon} />
                  )}
                  {item.name}
                </button>
              ))}
            </>
          )}
        </Box>
      </Modal>
    </div>
  );
}
