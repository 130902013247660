import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
import { getTranlation } from "../helpers/utils";

export const getLanguage = createAsyncThunk(
  "LanguageSlice/getLanguage",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_LOCAL_API}/languages`
      );
      return response.data.data.Languages;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.response.data);
    }
  }
);

export const LanguageSlice = createSlice({
  name: "language",
  initialState: {
    language: [],
    selLanguageId: "",
    englishId: "",
    isEnglish: true,
  },
  reducers: {
    setLanguageId: (state, action) => {
      state.selLanguageId = action.payload;
      if (state.englishId === state.selLanguageId) {
        state.isEnglish = true;
      } else {
        state.isEnglish = false;
      }
    },
  },
  extraReducers: {
    [getLanguage.fulfilled]: (state, action) => {
      state.language = action.payload;
      state.language.forEach((lan) => {
        if (lan.language === "English") {
          state.englishId = lan._id;
        }
      });
    },
    [getLanguage.rejected]: (state, action) => {
      toast.error(action.payload);
    },
  },
});
export const { setLanguageId } = LanguageSlice.actions;

export default LanguageSlice.reducer;
