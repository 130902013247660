import { createSlice } from "@reduxjs/toolkit";

export const TabSlice = createSlice({
  name: "tab",
  initialState: {
    tab: "HumQadam",
  },
  reducers: {
    changeTab: (state, action) => {
      state.tab = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { changeTab } = TabSlice.actions;

export default TabSlice.reducer;
