import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import logo from "../assets/img/HomePage/humqadamLogo.svg";

const PrivacyPolicy = () => {
  // const [pages, setPages] = useState([]);
  // const dispatch = useDispatch();
  // const { selLanguageId } = useSelector((state) => state.language);
  // const [privacyTitle, setPrivacyTitle] = useState("");
  // const getPages = async () => {
  //   try {
  //     const response = await axios.get(
  //       `${process.env.REACT_APP_LOCAL_API}/pages`
  //     );
  //     console.log(response.data.data.Pages);
  //     setPages(response.data.data.Pages);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  // useEffect(() => {
  //   // dispatch(getPages({ selLanguageId }));
  //   getPages();
  // }, []);
  // useEffect(() => {
  //   if (pages.length > 0) {
  //     for (let i = 0; i < pages.length; i++) {
  //       if (pages[i].title[0].text === "Privacy Policy") {
  //         setPrivacyTitle(pages[i].description[0].text);
  //       }
  //     }
  //   }
  // }, [pages]);

  return (
    <div className="p-10 mx-auto" style={{ maxWidth: 1366 }}>
      <div className="flex items-center justify-between mb-10">
        <h1 className="text-3xl font-bold">
          Privacy Policy - HumQadam (GBV Response Guide)
        </h1>
        <img src={logo} className="h-24" alt="HumQadam Logo" />
      </div>
      {/* <div dangerouslySetInnerHTML={{ __html: privacyTitle }} /> */}
      <div>
        <p>
          <strong>I. </strong>{" "}
          <strong>
            <u>INTRODUCTION</u>
          </strong>
          : <u></u>
        </p>

        <p>
          (A) Welcome to <em>Humqadam's</em> privacy policy (the{" "}
          <strong>“Policy”</strong>).
        </p>

        <p>
          (B) <em>Humqadam</em> is an online platform that, <em>inter alia</em>,
          contains a repository of information intended to serve as a referral
          guide and a service directory which can assist in cases of violence
          against women. (the “<strong>Services</strong>”). The Services are
          provided at no cost and is intended for use as is. The platform is
          owned, organized, and operated by Shirkat Gah - Women Resource Centre
          (\"Shirkat Gah\").
        </p>

        <p>
          (C) This Policy has been issued by Shirkat Gah, its subsidiaries and
          its affiliates, (together “<strong>we</strong>”, “<strong>us</strong>”
          and “ <strong>our</strong>”) and is addressed to individuals outside
          our organization with whom we interact, in connection with our{" "}
          <em>Humqadam</em> application that may be accessible or available via
          multiple devices, platforms and other means, whether owned and / or
          operated by us or by third parties, including, without limitation, the
          webpage(s) (the <strong>“App”</strong>) as well as individuals who are
          users of our App (together, “<strong>you</strong>”). For the purposes
          of this Policy, Shirkat Gah is the controller.
        </p>

        <p>
          (D) This Policy contains details on issues related to your privacy
          when using the App. It is intended to inform you of our policies,
          procedures, and practices regarding the collection, use and disclosure
          of any information that you provide through the App.
        </p>

        <p>
          (E) The Policy is part of our User Agreement which can also be found
          on the 'About Us' section of the App. The terms in this Policy
          (including but not limited to, \"we\", \"our\", “us\") have the same
          meaning as in our User Agreement. When you use our App, you accept and
          agree to both the User Agreement and to this Policy.
        </p>

        <p>
          (F) Considering the principles of purpose limitation and data
          minimization, we have endeavored to collect data that is necessary for
          the functioning, operation, and maintenance of this App. However, if
          you do not agree to be bound to the Policy, we would suggest that you
          stop using the App immediately.
        </p>

        <p>
          (G) By accessing and using our App you affirm that you have read the
          User Agreement and this Policy and that you understand, agree, and
          acknowledge all the terms contained in both of them.
        </p>

        <p>
          <strong>II. </strong>{" "}
          <strong>
            <u>THIRD-PARTY LINKS</u>
          </strong>
          <u></u>
        </p>

        <p>
          (A) Our App may use third party links, applications and / or content.
          If you choose to interact with any such links, application and/or
          content, your information (such as location) may be shared with the
          relevant third-party provider. We have no control over such third
          parties and are not responsible for their content or their handling of
          information. Since this Policy does not apply in that situation, we
          recommend that you review that third party’s privacy policy before
          accessing or interacting with its link, application and/or content.
        </p>

        <p>
          <strong>III. </strong>{" "}
          <strong>
            <u>COLLECTION OF INFORMATION</u>
          </strong>
          <u></u>
        </p>

        <p>
          (A) We use different methods to collect data from and about you
          through:
        </p>

        <p>
          ● <u>Data you provide</u>: We may collect your personal data that you
          provide to us (e.g., where you contact us via email or telephone, or
          by any other means, or when you provide us with information or your
          input through discussions, meetings, surveys or focus groups).
        </p>

        <p>
          ● <u>Relationship data</u>: We may collect or obtain your personal
          data in the ordinary course of our relationship with you.
        </p>

        <p>
          ● <u>App / Site data</u>: We may collect or obtain your personal data
          when you download /use our App.
        </p>

        <p>
          ● <u>Details:</u> We collect information regarding language
          preferences and policy consent provided by you when you use our App;
        </p>

        <p>
          ● <u>Surveys:</u> We collect the information that you provide to us on
          any survey, form, or questionnaire that we may require you to answer.
        </p>

        <p>
          ● <u>Technical Support Requests:</u> We may collect information (such
          as description of the issue, email address), that you provide to us
          when you need technical support related to App.
        </p>
        <p> (B) We collect and process the following information:</p>
        <p>
          ● <u>Language Preference</u>: This means information relating to your
          language preferences while using the application.
        </p>

        <p>
          ● <u>Consent Records</u>:<strong> </strong>This includes records of
          any consents you may have given, together with the date and time and
          means of consent (including any complaints that we receive from you
          about the App).
        </p>

        <p>
          ● <u>Device information</u>: This includes any information that is
          automatically collected by the Android OS about the computer, mobile
          device, or any other device used to access our App, such as browser
          type, operating system etc.;
        </p>

        <p>
          (C) <u>Additional Information</u>: We may need to request specific
          information from you to help us confirm your identity. We may also
          contact you to ask you for further information in relation to your
          request to speed up our response.
        </p>

        <p>
          (D){" "}
          <strong>
            <u>Cookies</u>
          </strong>
          : We do not use cookies to understand, operate, and generate our
          content. For further information, please see the aforementioned clause
          III of the Policy.
        </p>

        <p>
          <strong>IV. </strong>{" "}
          <strong>
            <u>USE OF INFORMATION</u>
          </strong>
          <strong> </strong>
        </p>

        <p>
          (A) We may use or process your information based on having your prior,
          express consent to do so; or where it is required by the applicable
          law; or where we have a legitimate interest in carrying out the
          processing for the purpose of managing, operating, or promoting our
          Services.
        </p>

        <p>
          (B) We use the information we have (subject to choices you make) for
          the following purposes:
        </p>

        <p>
          ● <u>Our App</u>: enabling you to access our App; operating,
          providing, updating, generating, and customizing our content;
          displaying information to you; communicating and interacting with you;
          notifying you of changes; improving your user experience.
        </p>

        <p>
          ● <u>Communication:</u> communicating with you via any media
          (including telephone, email, text message, or in person etc.) to
          troubleshoot any issues or problems you may be having.<u></u>
        </p>

        <p>
          ● <u>Systems &amp; Security</u>: management of our communications
          systems; operation of IT security systems; IT security and system
          audits; health and safety assessments; record keeping; compliance with
          regulatory requirements; physical security of premises (records of
          visitor logs etc.), and electronic security (login records etc.).
          <u></u>
        </p>

        <p>
          ● <u>Surveys</u>: engaging with you for the purposes of obtaining your
          views on our content / Services or other matters, and services of
          third parties.<u></u>
        </p>

        <p>
          ● <u>Legal</u>: complying with our legal and regulatory obligations
          under applicable law, pursuing or defending our legal rights;
          compliance with policies (including detecting, investigating, and
          preventing potential breaches of it); protecting the rights, property,
          and safety of us, our users or the public; investigating, detecting,
          preventing, and reporting any security or technical issues or
          illegality in accordance with applicable law.<u></u>
        </p>

        <p>
          <strong>V. </strong>{" "}
          <strong>
            <u>ACCESS TO YOUR PERSONAL INFORMATION</u>
          </strong>
          <u></u>
        </p>

        <p>
          (A) You may access your consent records in order to view, update
          and/or correct. We may require identification from you before
          releasing the requested information.
        </p>

        <p>(B) We may disclose your personal data to third parties such as:</p>

        <p>
          ● Legal and regulatory authorities either upon their request, or for
          the purposes of reporting any actual or suspected breach of
          law/regulation.
        </p>

        <p>
          ● Our accountants, auditors, lawyers, or other outside professional
          advisors (subject to binding confidentiality obligations).
        </p>

        <p>
          ● Third party processors (such as website developers and maintenance
          providers, IT support, database service providers) for the purposes of
          providing services to us subject to confidentiality obligations.
        </p>

        <p>
          ● Any relevant parties, law enforcement agencies or courts / tribunals
          or other such forums to the extent necessary for the pursuit or
          defence of our legal rights; or for the prevention, investigation,
          detection or prosecution of criminal offences or the execution of
          criminal penalties or preventing threats to public security.
        </p>

        <p>
          ● Prospective buyer(s), purchaser(s), or acquirer(s), as part of any
          valuation or acquisition of the platform and subject to
          confidentiality obligations. Further, in the event, we transfer the
          control of all or parts of the App to any person/entity, then the
          information provided by you, to the extent that it is relevant to any
          part of our business so transferred, will be transferred along with
          that part.
        </p>

        <p>
          <strong>VI. </strong>{" "}
          <strong>
            <u>SECURITY</u>
          </strong>
        </p>

        <p>
          (A) The minimum information we store (such as language preferences and
          consent to our policies) is done in a manner that reasonably protects
          it from misuse, loss, unlawful or accidental destruction, unauthorized
          access, modification, or disclosure. However, as the internet is an
          open system, the transmission of information via the internet can
          never be completely secure. Therefore, we cannot guarantee the
          security of your data transmitted to us using the internet. Any such
          transmission is at your risk and you are responsible for ensuring any
          information you send to us is sent in a secure manner.
        </p>

        <p>
          <strong>VII. </strong>{" "}
          <strong>
            <u>DISCONTINUING USE OF THE APP</u>
          </strong>
          <u></u>
        </p>

        <p>
          (A) You can discontinue using the Services by not opening the App or
          uninstalling the App from your device. In case you wish to resume
          access to Services, you can simply open or re-install the App on your
          device.
        </p>

        <p>
          (B) We reserve the right to retain copies of your information for as
          long as we maintain an ongoing relationship with you, or for any other
          purposes arising out of or in connection with our services. Further,
          we may also retain any information that is required to be preserved or
          retained under the applicable law for the prescribed minimum periods.
          Similarly, if any relevant legal claims are brought and/or are
          pending, we may continue to process/retain your information for such
          additional periods as are necessary in connection with them.
        </p>

        <p>
          <strong>VIII. </strong>{" "}
          <strong>
            <u>MISCELLANEOUS</u>
          </strong>
          <u></u>
        </p>

        <p>
          (A) By using our content, you consent to the collection and use of
          your information in accordance with this Policy.
        </p>

        <p>
          (B) This Policy may be amended or updated from time to time to reflect
          changes in our practices with respect to the processing of personal
          data, or changes in applicable law. Therefore, we encourage you to
          read this Policy carefully, and to regularly check this page to review
          any changes we might make.
        </p>

        <p>
          (C) If you have any queries or complaints about our Policy, please
          reach us via the Feedback form included in the app.
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
