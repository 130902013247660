export const getTranlation = (translations, langId) => {
  for (let i = 0; i < translations.length; i++) {
    if (translations[i]?.language?._id === langId) return translations[i].text;
  }
  return "Translation N/A";
};

export const getTranlationArr = (translations, langId) => {
  let arr = [];
  translations.forEach((con) => {
    if (con?.language?._id === langId) arr.push(con.text);
  });
  return arr;
};

export const getTilte = (appContent, name) => {
  for (let i = 0; i < appContent.length; i++) {
    if (appContent[i].name === name) {
      return appContent[i].title;
    }
  }
};
