import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
import { getTranlation } from "../helpers/utils";

export const getPages = createAsyncThunk(
  "PageSlice/getPages",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_LOCAL_API}/pages`
      );
      return { langId: data.selLanguageId, res: response.data.data.Pages };
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.response.data);
    }
  }
);

export const PageSlice = createSlice({
  name: "pages",
  initialState: {
    pages: [],
    backToGuide: false,
    backFromSettings: "HumQadam",
  },
  reducers: {
    setBackToGuide: (state, action) => {
      state.backToGuide = action.payload;
    },
    setBackFromSettings: (state, action) => {
      state.backFromSettings = action.payload;
    },
  },
  extraReducers: {
    [getPages.fulfilled]: (state, action) => {
      state.pages = action.payload.res.map((lang) => {
        let trans = { ...lang };
        trans.key = lang.title.find(
          (t) => t.language.language === "English"
        ).text;
        trans.title = getTranlation(lang.title, action.payload.langId);
        trans.description = getTranlation(
          lang.description,
          action.payload.langId
        );
        return trans;
      });
    },
    [getPages.rejected]: (state, action) => {
      toast.error(action.payload);
    },
  },
});
export const { setBackToGuide, setBackFromSettings } = PageSlice.actions;

export default PageSlice.reducer;
