import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import referral from "../assets/img/HomePage/referral.svg";
import ModalAbout from "../components/ModalAbout";
import { makeStyles, styled } from "@mui/styles";
import { Box, CircularProgress, Grid, Paper, Typography } from "@mui/material";
import Options from "../components/Options";
import Search from "../assets/img/Referral/search.svg";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import { changeTab } from "../redux/TabSlice";
import {
  getNationalHelplines,
  getProvincialHelplines,
  getServiceProviders,
  setSelectedItem,
} from "../redux/OrganizationSlice";
import { getTilte } from "../helpers/utils";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useTheme } from "@emotion/react";
import ArrowBack from "../assets/img/HumQadamHome/arrowBack.svg";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const useStyles = makeStyles((theme) => ({
  bgcolor: {
    background: "#FFFFFF",
  },
  textColor: {
    color: theme.palette.referral.main,
  },
  input: {
    "&::placeholder": {
      color: theme.palette.referral.main,
    },
  },

  sectionbg: {
    background: theme.palette.primary.main,
  },

  sectionBgLight: {
    backgroundColor: "#fceff7",
  },

  sectionLight: {
    backgroundColor: "#f2dce8",
  },

  emergencyBg: {
    backgroundColor: theme.palette.referral.light,
  },

  emergencyTextBg: {
    backgroundColor: theme.palette.referral.main,
  },

  fullScreen: {
    minHeight: "100vh",
  },
  height: {
    maxHeight: "50vh",
  },
}));

function ServicesDetail() {
  const theme = useTheme();
  const classes = useStyles();
  const { tab } = useSelector((state) => state.tab);
  const { selLanguageId, isEnglish } = useSelector((state) => state.language);
  const { selDistrictId } = useSelector((state) => state.district);
  const { selProvinceId } = useSelector((state) => state.province);
  const { serviceProviders, nationalHelplines, provincialHelplines } =
    useSelector((state) => state.organizations);
  const [openAbout, setOpenAbout] = useState(false);
  const [openItem, setOpenItem] = useState(false);
  const handleOpen = () => setOpenAbout(true);
  const handleClose = () => setOpenAbout(false);
  const [showData, setShowData] = useState({});
  const [filteredData, setFilteredData] = useState({});
  const [searchStr, setSearchStr] = useState("");
  const [noData, setNoData] = useState(false);
  const [showItems, setShowItems] = useState();
  const { appContent, selectedProvider } = useSelector(
    (state) => state.appContent
  );
  const [referralTitle, setReferralTitle] = useState("");

  const handleCloseItem = () => setOpenItem(false);
  const [tabSelect, setTabSelect] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    if (tab === "National Helplines")
      dispatch(getNationalHelplines({ langId: selLanguageId }));
  }, [selLanguageId]);

  useEffect(() => {
    if (tab === "Service Providers") {
      if (selDistrictId) {
        dispatch(getServiceProviders({ selDistrictId, langId: selLanguageId }));
      }
    }
  }, [selLanguageId, selDistrictId]);

  useEffect(() => {
    if (tab === "Provincial Helplines") {
      if (selProvinceId) {
        dispatch(
          getProvincialHelplines({ selProvinceId, langId: selLanguageId })
        );
      }
    }
  }, [selLanguageId, selProvinceId]);

  useEffect(() => {
    if (tab === "Service Providers") {
      setShowData(serviceProviders);
      setFilteredData(serviceProviders);
    } else if (tab === "Provincial Helplines") {
      setShowData(provincialHelplines);
      setFilteredData(provincialHelplines);
    } else if (tab === "National Helplines") {
      setShowData(nationalHelplines);
      setFilteredData(nationalHelplines);
    }
  }, [serviceProviders, provincialHelplines, nationalHelplines]);

  useEffect(() => {
    if (tabSelect) dispatch(changeTab(tabSelect));
  }, [tabSelect]);

  useEffect(() => {
    const filteredResults = {};
    for (const key in showData) {
      if (showData.hasOwnProperty(key)) {
        const array = showData[key];
        const filteredArray = array.filter((item) =>
          item?.title?.toLowerCase().includes(searchStr.toLowerCase())
        );
        if (filteredArray.length > 0) filteredResults[key] = filteredArray;
      }
    }
    setFilteredData(filteredResults);
    if (searchStr !== "" && Object.keys(filteredResults).length === 0) {
      console.log("No Data");
      setNoData(true);
    } else {
      setNoData(false);
    }
  }, [searchStr]);

  const handleKey = (key) => {
    setShowItems(key);
    if (key === showItems) setShowItems("");
  };

  const data = [
    {
      id: 1,
      text: "About",
    },
    {
      id: 2,
      text: "Partners",
    },
    {
      id: 3,
      text: "Privacy Policy",
    },
    {
      id: 4,
      text: "Terms and Conditions",
    },
  ];

  useEffect(() => {
    if (appContent) {
      setReferralTitle(getTilte(appContent, "Referral Directory"));
    }
  }, [appContent]);

  return (
    <div
      className={`${classes.sectionBgLight} flex pb-64 flex-col justify-between`}
    >
      <div>
        <Header />
        <div className="flex-col md:flex-row flex justify-between">
          <div className=" block md:hidden mb-5">
            <Options handleOpen={handleOpen} />
          </div>
          <div className="flex mx-5 mt-2 content-center items-center">
            <img className="w-6 mr-3 mb-2" src={referral} alt="Emergency" />
            <div className="flex flex-col md:flex-row">
              <Typography
                onClick={() => setTabSelect("ReferralDirectory")}
                className="mr-3 cursor-pointer"
                color={"black"}
                variant="p"
              >
                {referralTitle} {">"}
              </Typography>
              <Typography className="font-bold" color={"black"} variant="p">
                {selectedProvider}
              </Typography>
            </div>
          </div>
          <div className=" hidden md:block">
            <Options handleOpen={handleOpen} />
          </div>
        </div>
        <div className="flex flex-col justify-center content-center items-center my-2">
          <div className="flex px-4 w-64 md:w-96 py-1 mt-2 bg-white border-2 border-pink-700 rounded-xl">
            <input
              className={clsx("w-full focus:outline-none", classes.input)}
              type="text"
              placeholder="Search"
              onChange={(e) => setSearchStr(e.target.value)}
            />
            <img className="cursor-pointer" src={Search} alt="Search" />
          </div>
        </div>
        {noData ? (
          <div></div>
        ) : (
          <div>
            {Object.keys(filteredData).length === 0 ? (
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <CircularProgress />
              </Box>
            ) : (
              <div>
                <div className="flex flex-col justify-center content-center items-center my-2">
                  <div className={clsx("w-full px-10 my-5")}>
                    <Grid container spacing={2}>
                      {Object.keys(filteredData).map((key, index) => (
                        <Grid item xs={12} md={12} key={index}>
                          <Typography
                            variant="p"
                            component="div"
                            onClick={() => handleKey(key)}
                            className={clsx(
                              "text-center text-base cursor-pointer p-4 text-white rounded-2xl",
                              classes.sectionbg
                            )}
                          >
                            {key}
                          </Typography>
                          {showItems === key && (
                            <div className=" mt-4">
                              <Grid container spacing={2}>
                                {filteredData[key].map((item, index) => (
                                  <Grid item xs={12} md={4} key={index}>
                                    <div
                                      key={index}
                                      className={clsx(
                                        "h-20 p-3 flex items-center justify-center rounded-2xl my-1 cursor-pointer",
                                        classes.sectionLight
                                      )}
                                      onClick={() => {
                                        setTabSelect("HelplineDetails");
                                        dispatch(setSelectedItem(item));
                                      }}
                                    >
                                      <Typography
                                        className={`text-center ${classes.textColor}`}
                                        variant="p"
                                      >
                                        {item.title}
                                      </Typography>
                                    </div>
                                  </Grid>
                                ))}
                              </Grid>
                            </div>
                          )}
                        </Grid>
                      ))}
                    </Grid>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
      <img
        alt="Arrow Back"
        onClick={() => setTabSelect("ReferralDirectory")}
        src={ArrowBack}
        className="mt-16 cursor-pointer self-start ml-5"
      />
      <div>
        <ModalAbout open={openAbout} handleClose={handleClose} />
      </div>
    </div>
  );
}

export default ServicesDetail;
