import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import emergency from "../assets/img/HomePage/emergency.svg";
import referral from "../assets/img/HomePage/referral.svg";
import guidance from "../assets/img/HomePage/guidance.svg";
import laws from "../assets/img/HomePage/laws.svg";
import Footer from "../components/Footer";
import ModalAbout from "../components/ModalAbout";
import { makeStyles } from "@mui/styles";
import { createTheme } from "@mui/material";
import Options from "../components/Options";
import { useDispatch, useSelector } from "react-redux";
import { changeTab } from "../redux/TabSlice";
import { getAppContent } from "../redux/AppContentSlice";
import { getTilte } from "../helpers/utils";
import { setBackToGuide } from "../redux/PageSlice";
import ArrowBack from "../assets/img/HumQadamHome/arrowBack.svg";

const theme = createTheme();

const useStyles = makeStyles((theme) => ({
  bgcolor: {
    background: "#FFFFFF",
  },
  textColor: {
    color: theme.palette.primary.main,
  },

  sectionbg: {
    background: theme.palette.primary.main,
  },

  sectionBgLight: {
    backgroundColor: "#F6F1F4",
  },

  emergencyBg: {
    backgroundColor: theme.palette.emergency.light,
  },

  emergencyTextBg: {
    backgroundColor: theme.palette.emergency.main,
  },
  referralBg: {
    backgroundColor: theme.palette.referral.light,
  },

  referralTextBg: {
    backgroundColor: theme.palette.referral.main,
  },
  guidanceBg: {
    backgroundColor: theme.palette.guidance.light,
  },

  guidanceTextBg: {
    backgroundColor: theme.palette.guidance.main,
  },
  lawsBg: {
    backgroundColor: theme.palette.laws.light,
  },

  lawsTextBg: {
    backgroundColor: theme.palette.laws.main,
  },

  mHeigth: {
    minHeight: "800px",
    maxHeight: "800px",
  },
}));

const HumQadamHome = ({ onClose }) => {
  const classes = useStyles();
  const { selLanguageId } = useSelector((state) => state.language);
  const { selDistrictId } = useSelector((state) => state.district);
  const { selProvinceId } = useSelector((state) => state.province);
  const { appContent } = useSelector((state) => state.appContent);
  const [openAbout, setOpenAbout] = useState(false);
  const [tabSelect, setTabSelect] = useState("HumQadam");
  const [emergencyTitle, setEmergencyTitle] = useState("");
  const [referralTitle, setReferralTitle] = useState("");
  const [guidanceTitle, setGuidanceTitle] = useState("");
  const [lawsTitle, setLawsTitle] = useState("");
  const dispatch = useDispatch();
  const { tab } = useSelector((state) => state.tab);
  const handleOpen = () => setOpenAbout(true);
  const handleClose = () => {
    setOpenAbout(false);
  };

  useEffect(() => {
    if (appContent) {
      setEmergencyTitle(getTilte(appContent, "Emergency Services"));
      setReferralTitle(getTilte(appContent, "Referral Directory"));
      setGuidanceTitle(getTilte(appContent, "Step by Step Guidance"));
      setLawsTitle(getTilte(appContent, "Relevant Laws"));
    }
  }, [appContent]);

  useEffect(() => {
    dispatch(setBackToGuide(false));
  }, []);

  useEffect(() => {
    dispatch(changeTab(tabSelect));
  }, [tabSelect]);

  return (
    <div className={`${classes.sectionBgLight} flex flex-col justify-between`}>
      <div>
        <Header />
        <Options handleOpen={handleOpen} />
        <div className="px-5 mt-5 mb-48">
          <div className="flex flex-col sm:flex-row justify-center items-center">
            <div
              onClick={() => setTabSelect("EmergencyServices")}
              className={`m-2 w-64 h-52 flex-col justify-between flex cursor-pointer ${classes.emergencyBg} rounded-xl`}
            >
              <div>
                <img
                  src={emergency}
                  alt="Appstore"
                  className="px-3 py-5 h-32 mt-4 lg:mt-0 lg:h-36 mx-auto"
                />
              </div>
              <p
                className={`${classes.emergencyTextBg} text-center rounded-b-xl p-2 text-md text-white`}
              >
                {emergencyTitle}
              </p>
            </div>
            <div
              onClick={() => setTabSelect("ReferralDirectory")}
              className={`m-2  w-64 h-52 flex-col justify-between flex cursor-pointer ${classes.referralBg} rounded-xl`}
            >
              <div>
                <img
                  src={referral}
                  alt="Appstore"
                  className="px-3 py-5 h-32 mt-4 lg:mt-0 lg:h-36 mx-auto"
                />
              </div>
              <p
                className={`${classes.referralTextBg} text-center rounded-b-xl p-2 text-md text-white`}
              >
                {referralTitle}
              </p>
            </div>
          </div>
          <div className="flex flex-col sm:flex-row justify-center items-center">
            <div
              onClick={() => setTabSelect("StepGuide")}
              className={`m-2  w-64 h-52 flex-col justify-between flex cursor-pointer ${classes.guidanceBg} rounded-xl`}
            >
              <div>
                <img
                  src={guidance}
                  alt="Appstore"
                  className="px-3 py-5 h-32 mt-4 lg:mt-0 lg:h-36 mx-auto"
                />
              </div>
              <p
                className={`${classes.guidanceTextBg} text-center rounded-b-xl py-2 text-md text-white`}
              >
                {guidanceTitle}
              </p>
            </div>
            <div
              onClick={() => setTabSelect("Laws")}
              className={`m-2  w-64 h-52 flex-col justify-between flex cursor-pointer ${classes.lawsBg} rounded-xl`}
            >
              <div>
                <img
                  src={laws}
                  alt="Appstore"
                  className="px-3 py-5 h-32 mt-4 lg:mt-0 lg:h-36 mx-auto"
                />
              </div>
              <p
                className={`${classes.lawsTextBg} text-center rounded-b-xl p-2 text-md text-white`}
              >
                {lawsTitle}
              </p>
            </div>
          </div>
          <img
            alt="Arrow Back"
            onClick={() => onClose()}
            src={ArrowBack}
            className="mt-16 cursor-pointer self-start ml-5"
          />
        </div>
      </div>
      <div>
        <ModalAbout open={openAbout} handleClose={handleClose} />
      </div>
    </div>
  );
};

export default HumQadamHome;
