import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import referral from "../assets/img/HomePage/referral.svg";
import ModalAbout from "../components/ModalAbout";
import { makeStyles } from "@mui/styles";
import { Typography } from "@mui/material";
import Options from "../components/Options";
import national from "../assets/img/Referral/national.svg";
import provincial from "../assets/img/Referral/provincial.svg";
import service from "../assets/img/Referral/service.svg";
import members from "../assets/img/Referral/members.svg";
import { changeTab } from "../redux/TabSlice";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import { getTilte } from "../helpers/utils";
import {
  setSelectedProvider,
  setSelectedProviderEng,
} from "../redux/AppContentSlice";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useTheme } from "@emotion/react";
import ArrowBack from "../assets/img/HumQadamHome/arrowBack.svg";

const useStyles = makeStyles((theme) => ({
  bgcolor: {
    background: "#FFFFFF",
  },
  textColor: {
    color: theme.palette.primary.main,
  },

  sectionbg: {
    background: theme.palette.primary.main,
  },

  sectionBgLight: {
    backgroundColor: "#EEE3EA",
  },

  referralBg: {
    backgroundColor: theme.palette.referral.light,
  },

  referralTextBg: {
    backgroundColor: theme.palette.referral.main,
  },

  width500: {
    maxWidth: "500px",
    [theme.breakpoints.down("md")]: {
      maxWidth: "300px",
    },
  },
}));

function ReferralDirectory() {
  const theme = useTheme();
  const classes = useStyles();
  const [openAbout, setOpenAbout] = useState(false);
  const { isEnglish } = useSelector((state) => state.language);
  const [tabSelect, setTabSelect] = useState("ReferralDirectory");
  const { appContent } = useSelector((state) => state.appContent);
  const [nationalTitle, setNationalTitle] = useState("");
  const [provincialTitle, setProvincialTitle] = useState("");
  const [serviceTitle, setServiceTitle] = useState("");
  const [membersTitle, setMembersTitle] = useState("");
  const [referralTitle, setReferralTitle] = useState("");
  const [referralDes, setReferralDes] = useState("");
  const dispatch = useDispatch();
  const handleOpen = () => setOpenAbout(true);
  const handleClose = () => {
    setOpenAbout(false);
  };

  useEffect(() => {
    dispatch(changeTab(tabSelect));
  }, [tabSelect]);

  const data = [
    {
      id: 1,
      text: "About",
    },
    {
      id: 2,
      text: "Partners",
    },
    {
      id: 3,
      text: "Privacy Policy",
    },
    {
      id: 4,
      text: "Terms and Conditions",
    },
  ];

  useEffect(() => {
    if (appContent) {
      setNationalTitle(getTilte(appContent, "National Helplines"));
      setProvincialTitle(getTilte(appContent, "Provincial Helplines"));
      setServiceTitle(getTilte(appContent, "Service Providers"));
      setMembersTitle(getTilte(appContent, "Members of Parliament"));
      setReferralTitle(getTilte(appContent, "Referral Directory"));
      setReferralDes(getTilte(appContent, "ReferralDes"));
    }
  }, [appContent]);

  const dataShow = [
    {
      id: 1,
      text: nationalTitle,
      img: national,
      nextPage: "National Helplines",
    },
    {
      id: 2,
      text: provincialTitle,
      img: provincial,
      nextPage: "Provincial Helplines",
    },
    {
      id: 3,
      text: serviceTitle,
      img: service,
      nextPage: "Service Providers",
    },
    // {
    //   id: 4,
    //   text: membersTitle,
    //   img: members,
    //   nextPage: "no",
    // },
  ];
  return (
    <div
      className={`${classes.sectionBgLight} ${classes.fullScreen} flex flex-col justify-between`}
    >
      <Header />
      <Options handleOpen={handleOpen} />
      <div className="flex flex-col justify-center content-center items-center mb-40">
        <img className=" w-12 mb-2" src={referral} alt="Emergency" />
        <Typography
          variant="p"
          className={clsx(classes.textColor, "text-xl font-bold mb-4 mt-2")}
        >
          {referralTitle}
        </Typography>
        <Typography
          className={clsx("text-center", classes.textColor, classes.width500)}
          variant="p"
        >
          {referralDes}
        </Typography>
        <div>
          {dataShow.map((item, index) => (
            <div
              key={item._id}
              className={clsx(
                "p-3 w-96 my-3 rounded-xl flex items-center cursor-pointer",
                isEnglish ? "flex-row" : "flex-row-reverse",
                classes.sectionbg
              )}
              onClick={() => {
                dispatch(setSelectedProvider(item.text));
                dispatch(setSelectedProviderEng(item.nextPage));
                setTabSelect(item.nextPage);
              }}
            >
              <img
                onClick={(e) => {
                  e.stopPropagation();
                }}
                className="w-9 h-9 mr-5"
                src={item.img}
                alt="HelpLine"
              />
              <div className="flex flex-col">
                <Typography
                  variant="p"
                  color={"white"}
                  className={clsx("px-3")}
                >
                  {item.text}
                </Typography>
              </div>
            </div>
          ))}
          <div
            className={clsx(
              "p-3 w-96 my-3 rounded-xl flex items-center cursor-pointer",
              isEnglish ? "flex-row" : "flex-row-reverse",
              classes.sectionbg
            )}
          >
            <img
              onClick={(e) => {
                e.stopPropagation();
              }}
              className="w-9 h-9 mr-5"
              src={members}
              alt="HelpLine"
            />
            <div className="flex flex-col">
              <a
                href="https://na.gov.pk/en/all_members.php"
                target="_blank"
                className={clsx("px-3 text-white")}
                rel="noreferrer"
              >
                {membersTitle}
              </a>
            </div>
          </div>
        </div>
        <img
          alt="Arrow Back"
          onClick={() => setTabSelect("HomePage")}
          src={ArrowBack}
          className="mt-16 cursor-pointer self-start ml-5"
        />
      </div>
      <div>
        <ModalAbout open={openAbout} handleClose={handleClose} />
      </div>
    </div>
  );
}

export default ReferralDirectory;
