import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import emergency from "../assets/img/HomePage/emergency.svg";
import ModalAbout from "../components/ModalAbout";
import ModalEmergency from "../components/ModalEmergency";
import { makeStyles, styled } from "@mui/styles";
import { Box, CircularProgress, Grid, Paper, Typography } from "@mui/material";
import Options from "../components/Options";
import Search from "../assets/img/EmergencyServices/Search.svg";
import Map from "../assets/img/EmergencyServices/map.svg";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import { changeTab } from "../redux/TabSlice";
import {
  getMedicalServices,
  getPoliceServices,
} from "../redux/OrganizationSlice";
import { getTilte } from "../helpers/utils";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useTheme } from "@emotion/react";
import ArrowBack from "../assets/img/HumQadamHome/arrowBack.svg";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const useStyles = makeStyles((theme) => ({
  bgcolor: {
    background: "#FFFFFF",
  },
  textColor: {
    color: theme.palette.emergency.main,
  },
  input: {
    "&::placeholder": {
      color: theme.palette.emergency.main,
    },
  },

  sectionbg: {
    background: theme.palette.primary.main,
  },

  sectionBgLight: {
    backgroundColor: "#FFE2E3",
  },

  emergencyBg: {
    backgroundColor: theme.palette.emergency.light,
  },

  emergencyTextBg: {
    backgroundColor: theme.palette.emergency.main,
  },

  fullScreen: {
    minHeight: "100vh",
  },
  height: {
    maxHeight: "50vh",
  },
}));

function ServicesDetail() {
  const theme = useTheme();
  const classes = useStyles();
  const { tab } = useSelector((state) => state.tab);
  const { selLanguageId, isEnglish } = useSelector((state) => state.language);
  const { selDistrictId } = useSelector((state) => state.district);
  const { selProvinceId } = useSelector((state) => state.province);
  const { policeServices, medicalServices } = useSelector(
    (state) => state.organizations
  );
  const { appContent, selectedService } = useSelector(
    (state) => state.appContent
  );
  const [emergencyTitle, setEmergencyTitle] = useState("");
  const [referralTitle, setReferralTitle] = useState("");
  const [guidanceTitle, setGuidanceTitle] = useState("");
  const [lawsTitle, setLawsTitle] = useState("");
  const [openAbout, setOpenAbout] = useState(false);
  const [openItem, setOpenItem] = useState(false);
  const handleOpen = () => setOpenAbout(true);
  const handleClose = () => setOpenAbout(false);
  const [items, setItems] = useState();
  const [item, setItem] = useState();
  const [filteredData, setFilteredData] = useState();
  const [searchStr, setSearchStr] = useState("");
  const handleCloseItem = () => setOpenItem(false);
  const [tabSelect, setTabSelect] = useState("");
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    if (selDistrictId) {
      dispatch(getMedicalServices({ selDistrictId, langId: selLanguageId }));
      dispatch(getPoliceServices({ selDistrictId, langId: selLanguageId }));
    }
  }, [selDistrictId, selLanguageId]);

  useEffect(() => {
    if (items) setLoading(false);
  }, [items]);

  useEffect(() => {
    let arr = [];
    items?.forEach((item) => {
      let lowerCaseSearchText = searchStr.toLowerCase();
      if (item.title.toLowerCase().includes(lowerCaseSearchText)) {
        arr.push(item);
      }
    });
    setFilteredData(arr);
  }, [searchStr]);

  useEffect(() => {
    if (appContent) {
      setEmergencyTitle(getTilte(appContent, "Emergency Services"));
      setReferralTitle(getTilte(appContent, "Referral Directory"));
      setGuidanceTitle(getTilte(appContent, "Step by Step Guidance"));
      setLawsTitle(getTilte(appContent, "Relevant Laws"));
    }
  }, [appContent]);

  useEffect(() => {
    if (tab === "PoliceServices") {
      setItems(policeServices);
      setFilteredData(policeServices);
    } else {
      setItems(medicalServices);
      setFilteredData(medicalServices);
    }
    if (tabSelect) {
      dispatch(changeTab(tabSelect));
    }
  }, [selLanguageId, policeServices, medicalServices, tabSelect]);

  const data = [
    {
      id: 1,
      text: "About",
    },
    {
      id: 2,
      text: "Partners",
    },
    {
      id: 3,
      text: "Privacy Policy",
    },
    {
      id: 4,
      text: "Terms and Conditions",
    },
  ];

  return (
    <div
      className={`${classes.sectionBgLight} pb-40 flex flex-col justify-between`}
    >
      <div>
        <Header />
        <div className="flex-col md:flex-row flex justify-between">
          <div className=" block md:hidden mb-5">
            <Options handleOpen={handleOpen} />
          </div>
          <div className="flex mx-5 content-center mt-2 items-center">
            <img className="w-6 mr-3 mb-2" src={emergency} alt="Emergency" />
            <div className="flex flex-col md:flex-row">
              <Typography
                onClick={() => setTabSelect("EmergencyServices")}
                className="mr-3 cursor-pointer"
                color={"black"}
                variant="p"
              >
                {emergencyTitle} {">"}
              </Typography>
              <Typography className="font-bold" color={"black"} variant="p">
                {selectedService}
              </Typography>
            </div>
          </div>
          <div className=" hidden md:block">
            <Options handleOpen={handleOpen} />
          </div>
        </div>
        {loading ? (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress />
          </Box>
        ) : (
          <div>
            <div className="flex flex-col justify-center content-center items-center my-2">
              <div className={clsx("w-full px-5 sm:w-4/6 my-5")}>
                <Grid container spacing={2}>
                  {filteredData?.length === 0 && (
                    <h1 className={`${classes.textColor}`}>No data...</h1>
                  )}
                  {filteredData?.map((item, index) => (
                    <Grid item xs={12} md={6} key={index}>
                      <div
                        className={clsx(
                          "p-3 rounded-xl flex justify-between items-center cursor-pointer",
                          classes.emergencyBg,
                          isEnglish ? "flex-row" : "flex-row-reverse"
                        )}
                        onClick={() => {
                          setItem(item);
                          setOpenItem(true);
                        }}
                      >
                        <div className="flex flex-col">
                          <Typography
                            variant="p"
                            color={"white"}
                            className={`font-bold ${
                              isEnglish ? "text-left" : "text-right"
                            }`}
                          >
                            {item?.title}
                          </Typography>
                          <Typography
                            variant="p"
                            color={"white"}
                            className={`font-bold ${
                              isEnglish ? "text-left" : "text-right"
                            }`}
                          >
                            {item?.contact[0]}
                          </Typography>
                        </div>
                      </div>
                    </Grid>
                  ))}
                </Grid>
              </div>
            </div>
          </div>
        )}
      </div>
      <img
        alt="Arrow Back"
        onClick={() => setTabSelect("EmergencyServices")}
        src={ArrowBack}
        className="mt-16 cursor-pointer self-start ml-5"
      />
      <div>
        <ModalAbout open={openAbout} handleClose={handleClose} />
        <ModalEmergency
          open={openItem}
          handleClose={handleCloseItem}
          item={item}
        />
      </div>
    </div>
  );
}

export default ServicesDetail;
