import { configureStore } from "@reduxjs/toolkit";
import TabSlice from "./TabSlice";
import LanguageSlice from "./LanguageSlice";
import DistrictSlice from "./DistrictSlice";
import ProvinceSlice from "./ProvinceSlice";
import OrganizationSlice from "./OrganizationSlice";
import OffenceSlice from "./OffenceSlice";
import AppContentSlice from "./AppContentSlice";
import PageSlice  from "./PageSlice";

const store = configureStore({
  reducer: {
    tab: TabSlice,
    language: LanguageSlice,
    district: DistrictSlice,
    province: ProvinceSlice,
    organizations: OrganizationSlice,
    offence: OffenceSlice,
    appContent: AppContentSlice,
    page: PageSlice,
  },
});

export default store;
