import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import { getTilte } from "../helpers/utils";
import { changeTab } from "../redux/TabSlice";

const useStyles = makeStyles((theme) => ({
  bgcolor: {
    background: "#FFFFFF",
  },
  textColor: {
    color: theme.palette.primary.main,
  },
  sectionbg: {
    background: theme.palette.primary.main,
  },
  medWidth: {
    [theme.breakpoints.up("md")]: {
      width: "500px",
    },
  },
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "#F6F1F4",
  p: 4,
};

export default function ModalAbout({ open, handleClose }) {
  const classes = useStyles();
  const { appContent } = useSelector((state) => state.appContent);
  const [aboutTitle, setAboutTitle] = useState("");
  const [partnersTitle, setPartnersTitle] = useState("");
  const { isEnglish } = useSelector((state) => state.language);
  const [privacyTitle, setPrivacyTitle] = useState("");
  const [termsTitle, setTermsTitle] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    if (appContent) {
      setAboutTitle(getTilte(appContent, "About"));
      setPartnersTitle(getTilte(appContent, "Partners"));
      setPrivacyTitle(getTilte(appContent, "Privacy Policy"));
      setTermsTitle(getTilte(appContent, "Terms and Conditions"));
    }
  }, [appContent]);

  const data = [
    {
      id: 1,
      text: aboutTitle,
      title: "About",
    },
    {
      id: 2,
      text: partnersTitle,
      title: "Partners"
    },
    {
      id: 3,
      text: privacyTitle,
      title: "Privacy"
    },
    {
      id: 4,
      text: termsTitle,
      title: "Terms"
    },
  ];
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          className={`border-2 border-red-100 rounded-2xl w-70 ${classes.medWidth}`}
          sx={style}
        >
          <h6 className={`text-center mb-6 ${classes.textColor} font-bold`}>
            {aboutTitle}
          </h6>
          {data.map((item) => (
            <button
              className={`modal-box md:w-72 w-60 block mx-auto ${classes.sectionbg} m-2 p-3 px-5 text-white ${isEnglish ? "text-left" : "text-right"} rounded-xl`}
              key={item.id}
              onClick={() => {
                handleClose()
                dispatch(changeTab(item.title))}}
            >
              {item.text}
            </button>
          ))}
        </Box>
      </Modal>
    </div>
  );
}
