import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import modalLogo from "../assets/img/HomePage/modalLogo.svg";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import { setLanguageId } from "../redux/LanguageSlice";
import { setDistrictId, setProvDistrict } from "../redux/DistrictSlice";
import { setProvinceId } from "../redux/ProvinceSlice";
import { CircularProgress } from "@mui/material";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  bgcolor: {
    background: "#FFFFFF",
  },
  textColor: {
    color: theme.palette.primary.main,
  },
  sectionbg: {
    background: theme.palette.primary.main,
  },
  medWidth: {
    [theme.breakpoints.up("md")]: {
      width: "500px",
    },
  },
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "#F6F1F4",
  p: 4,
};

export default function BasicModal({
  open,
  handleClose,
  head,
  data,
  img,
  isImage,
  type,
  onClose,
}) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { isEnglish } = useSelector((state) => state.language);
  const showText = (item) => {
    if (type === "language") return item.language;
    else return item.name;
  };
  const showImage = (item) => {
    if (type === "language") return "";
    else return item.icon;
  };
  const { federalId } = useSelector((state) => state.province);

  const setId = (item) => {
    if (type === "language") {
      dispatch(setLanguageId(item._id));
      handleClose();
    } else if (type === "district") {
      dispatch(setDistrictId(item._id));
      handleClose();
    } else {
      dispatch(setProvinceId(item._id));
      dispatch(setProvDistrict(item.districts));
      handleClose();
    }
  };

  return (
    <div>
      <Modal
        open={open}
        // handleClose={() => onClose()}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          className={`border-2 border-red-100 rounded-2xl w-70 ${classes.medWidth}`}
          sx={style}
        >
          {isImage && (
            <img
              className="mx-auto w-48 md:w-80 mb-3"
              src={modalLogo}
              alt="Modal Logo"
            />
          )}
          <h6 className={`text-center mb-6 ${classes.textColor} font-bold`}>
            {head}
          </h6>
          <img className="mx-auto mb-6" src={img} alt="Modal Logo" />
          {data.length <= 0 && (
            <div className=" flex items-center w-full justify-center">
              <CircularProgress />
            </div>
          )}
          {data.map((item) => (
            <div key={item._id}>
              {item._id !== federalId && (
                <button
                  className={clsx(
                    `md:w-96 w-72 block mx-auto ${
                      isEnglish ? "text-left" : "text-right"
                    } m-2 p-3 px-5 text-white text-left flex rounded-xl`,
                    item.icon && !isEnglish ? "flex-row-reverse" : " ",
                    classes.sectionbg
                  )}
                  onClick={() => setId(item)}
                >
                  {item.icon && (
                    <img className="w-7 h-7 mx-3" alt="icon" src={item.icon} />
                  )}
                  <p className="">{showText(item)}</p>
                </button>
              )}
            </div>
          ))}
        </Box>
      </Modal>
    </div>
  );
}
