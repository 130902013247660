import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
import { getTranlation, getTranlationArr } from "../helpers/utils";

export const getPoliceServices = createAsyncThunk(
  "OrganizationSlice/getPoliceServices",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_LOCAL_API}/police-services/${data.selDistrictId}`
      );
      return {
        res: response.data.data["Police Services"],
        langId: data.langId,
      };
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.response.data);
    }
  }
);

export const getMedicalServices = createAsyncThunk(
  "OrganizationSlice/getMedicalServices",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_LOCAL_API}/emergency-services/${data.selDistrictId}`
      );
      return {
        res: response.data.data["Medical Services"],
        langId: data.langId,
      };
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.response.data);
    }
  }
);

export const getServiceProviders = createAsyncThunk(
  "OrganizationSlice/getServicePrviders",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_LOCAL_API}/service-providers/${data.selDistrictId}`
      );
      return {
        res: response.data.data["Service Providers"],
        langId: data.langId,
      };
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.response.data);
    }
  }
);

export const getAllServiceProviders = createAsyncThunk(
  "OrganizationSlice/getServicePrviders",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_LOCAL_API}/all-service-providers/${data.selDistrictId}`
      );
      return {
        res: response.data.data["Service Providers"],
        langId: data.langId,
      };
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.response.data);
    }
  }
);

export const getNationalHelplines = createAsyncThunk(
  "OrganizationSlice/getNationalHelplines",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_LOCAL_API}/national-helplines`
      );
      return {
        res: response.data.data["National Helpline"],
        langId: data.langId,
      };
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.response.data);
    }
  }
);

export const getProvincialHelplines = createAsyncThunk(
  "OrganizationSlice/getProvincialHelplines",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_LOCAL_API}/provincial-helplines/${data.selProvinceId}`
      );
      return {
        res: response.data.data["Service Providers"],
        langId: data.langId,
      };
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.response.data);
    }
  }
);

export const OrganizationSlice = createSlice({
  name: "Organization",
  initialState: {
    policeServices: null,
    medicalServices: null,
    serviceProviders: {},
    nationalHelplines: {},
    provincialHelplines: {},
    providers: [],
    selectedItem: {},
  },
  reducers: {
    getTranslatedPoliceServices: (state, action) => {
      console.debug("Inside Get Translation Police");
      state.policeServices = action.payload.policeServices.map((obj) => {
        let transPoliceSer = { ...obj };
        transPoliceSer.title = getTranlation(obj.title, action.payload.langId);
        transPoliceSer.description = getTranlation(
          obj.description,
          action.payload.langId
        );
        transPoliceSer.service_type = getTranlation(
          obj.service_type,
          action.payload.langId
        );
        transPoliceSer.organizational_status = getTranlation(
          obj.organizational_status,
          action.payload.langId
        );
        let arr = [];
        obj.contact.forEach((con) => {
          arr.push(getTranlation(con, action.payload.langId));
        });
        transPoliceSer.contact = arr;
        return transPoliceSer;
      });
    },
    setSelectedItem: (state, action) => {
      state.selectedItem = action.payload;
    },
    resetOrgStates: (state, action) => {
      state.policeServices = null;
      state.medicalServices = null;
      state.serviceProviders = {};
      state.nationalHelplines = {};
      state.provincialHelplines = {};
      state.providers = [];
      state.selectedItem = {};
    },
  },
  extraReducers: {
    [getPoliceServices.fulfilled]: (state, action) => {
      state.policeServices = action.payload.res.map((obj) => {
        let trans = { ...obj };
        trans.title = getTranlation(obj.title, action.payload.langId);
        trans.description = getTranlation(
          obj.description,
          action.payload.langId
        );
        trans.service_type = getTranlation(
          obj.service_type,
          action.payload.langId
        );
        trans.organizational_status = getTranlation(
          obj.organizational_status,
          action.payload.langId
        );
        trans.address = getTranlationArr(obj.address, action.payload.langId);
        trans.contact = getTranlationArr(obj.contact, action.payload.langId);
        return trans;
      });
    },
    [getPoliceServices.rejected]: (state, action) => {
      toast.error(action.payload);
    },
    [getMedicalServices.fulfilled]: (state, action) => {
      state.medicalServices = action.payload.res.map((obj) => {
        let trans = { ...obj };
        trans.title = getTranlation(obj.title, action.payload.langId);
        trans.description = getTranlation(
          obj.description,
          action.payload.langId
        );
        trans.service_type = getTranlation(
          obj.service_type,
          action.payload.langId
        );
        trans.organizational_status = getTranlation(
          obj.organizational_status,
          action.payload.langId
        );
        trans.contact = getTranlationArr(obj.contact, action.payload.langId);
        trans.address = getTranlationArr(obj.address, action.payload.langId);
        return trans;
      });
    },
    [getMedicalServices.rejected]: (state, action) => {
      toast.error(action.payload);
    },
    [getServiceProviders.fulfilled]: (state, action) => {
      const items = action.payload.res.map((obj) => {
        let trans = { ...obj };
        trans.title = getTranlation(obj.title, action.payload.langId);
        trans.description = getTranlation(
          obj.description,
          action.payload.langId
        );
        trans.service_type = getTranlation(
          obj.service_type,
          action.payload.langId
        );
        trans.organizational_status = getTranlation(
          obj.organizational_status,
          action.payload.langId
        );
        trans.address = getTranlationArr(obj.address, action.payload.langId);
        trans.contact = getTranlationArr(obj.contact, action.payload.langId);
        return trans;
      });
      state.providers = items;
      const groupedData = {};
      items?.forEach((item) => {
        if (!groupedData[item.service_type]) {
          groupedData[item.service_type] = [item];
        } else {
          groupedData[item.service_type].push(item);
        }
      });
      state.serviceProviders = groupedData;
    },
    [getServiceProviders.rejected]: (state, action) => {
      toast.error(action.payload);
    },
    [getAllServiceProviders.fulfilled]: (state, action) => {
      const items = action.payload.res.map((obj) => {
        let trans = { ...obj };
        trans.title = getTranlation(obj.title, action.payload.langId);
        trans.description = getTranlation(
          obj.description,
          action.payload.langId
        );
        trans.service_type = getTranlation(
          obj.service_type,
          action.payload.langId
        );
        trans.organizational_status = getTranlation(
          obj.organizational_status,
          action.payload.langId
        );
        trans.address = getTranlationArr(obj.address, action.payload.langId);
        trans.contact = getTranlationArr(obj.contact, action.payload.langId);
        return trans;
      });
      state.providers = items;
      const groupedData = {};
      items?.forEach((item) => {
        if (!groupedData[item.service_type]) {
          groupedData[item.service_type] = [item];
        } else {
          groupedData[item.service_type].push(item);
        }
      });
      state.serviceProviders = groupedData;
    },
    [getAllServiceProviders.rejected]: (state, action) => {
      toast.error(action.payload);
    },
    [getNationalHelplines.fulfilled]: (state, action) => {
      const items = action.payload.res.map((obj) => {
        let trans = { ...obj };
        trans.title = getTranlation(obj.title, action.payload.langId);
        trans.description = getTranlation(
          obj.description,
          action.payload.langId
        );
        trans.service_type = getTranlation(
          obj.service_type,
          action.payload.langId
        );
        trans.organizational_status = getTranlation(
          obj.organizational_status,
          action.payload.langId
        );
        trans.address = getTranlationArr(obj.address, action.payload.langId);
        trans.contact = getTranlationArr(obj.contact, action.payload.langId);
        return trans;
      });
      const groupedData = {};
      items?.forEach((item) => {
        if (!groupedData[item.service_type]) {
          groupedData[item.service_type] = [item];
        } else {
          groupedData[item.service_type].push(item);
        }
      });
      state.nationalHelplines = groupedData;
    },
    [getNationalHelplines.rejected]: (state, action) => {
      toast.error(action.payload);
    },
    [getProvincialHelplines.fulfilled]: (state, action) => {
      const items = action.payload.res.map((obj) => {
        let trans = { ...obj };
        trans.title = getTranlation(obj.title, action.payload.langId);
        trans.description = getTranlation(
          obj.description,
          action.payload.langId
        );
        trans.service_type = getTranlation(
          obj.service_type,
          action.payload.langId
        );
        trans.organizational_status = getTranlation(
          obj.organizational_status,
          action.payload.langId
        );
        trans.address = getTranlationArr(obj.address, action.payload.langId);
        trans.contact = getTranlationArr(obj.contact, action.payload.langId);
        return trans;
      });
      const groupedData = {};
      items?.forEach((item) => {
        if (!groupedData[item.service_type]) {
          groupedData[item.service_type] = [item];
        } else {
          groupedData[item.service_type].push(item);
        }
      });
      state.provincialHelplines = groupedData;
    },
    [getProvincialHelplines.rejected]: (state, action) => {
      toast.error(action.payload);
    },
  },
});
export const { getTranslatedPoliceServices, setSelectedItem, resetOrgStates } =
  OrganizationSlice.actions;

export default OrganizationSlice.reducer;
