import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
import { getTranlation } from "../helpers/utils";

export const getProvince = createAsyncThunk(
  "ProvinceSlice/getProvince",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_LOCAL_API}/provinces`
      );
      return { langId: data.selLanguageId, res: response.data.data.Provinces };
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.response.data);
    }
  }
);

export const ProvinceSlice = createSlice({
  name: "province",
  initialState: {
    province: [],
    selProvinceId: "",
    federalId: "",
  },
  reducers: {
    setProvinceId: (state, action) => {
      state.selProvinceId = action.payload;
    },
    getTranslatedProvinces: (state, action) => {
      state.province = action.payload.provinces.map((lang) => {
        let transProvince = { ...lang };
        transProvince.name = getTranlation(lang.name, action.payload.langId);
        return transProvince;
      });
      state.province = state.province.sort((a, b) =>
        a.name.localeCompare(b.name)
      );
    },
  },
  extraReducers: {
    [getProvince.fulfilled]: (state, action) => {
      state.province = action.payload.res.map((lang) => {
        let transProvince = { ...lang };
        if (lang.name[0].text === "Federal") {
          state.federalId = lang._id;
        }
        transProvince.name = getTranlation(lang.name, action.payload.langId);
        return transProvince;
      });
      state.province = state.province.sort((a, b) =>
        a.name.localeCompare(b.name)
      );
    },
    [getProvince.rejected]: (state, action) => {
      toast.error(action.payload);
    },
  },
});
export const { getTranslatedProvinces, setProvinceId } = ProvinceSlice.actions;

export default ProvinceSlice.reducer;
