import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
import { getTranlation } from "../helpers/utils";

export const getAppContent = createAsyncThunk(
  "AppContent/getAppContent",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_LOCAL_API}/app-content`
      );
      return { langId: data.selLanguageId, res: response.data.data.AppContent };
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.response.data);
    }
  }
);

export const AppContent = createSlice({
  name: "appContent",
  initialState: {
    appContent: [],
    selectedProvider: "",
    selectedProviderEng: "",
    selectedService: "",
  },
  reducers: {
    setSelectedProvider: (state, action) => {
      state.selectedProvider = action.payload;
    },
    setSelectedProviderEng: (state, action) => {
      state.selectedProviderEng = action.payload;
    },
    setSelectedService: (state, action) => {
      state.selectedService = action.payload;
    },
  },
  extraReducers: {
    [getAppContent.fulfilled]: (state, action) => {
      state.appContent = action.payload.res.map((lang) => {
        let trans = { ...lang };
        trans.title = getTranlation(lang.title, action.payload.langId);
        return trans;
      });
    },
    [getAppContent.rejected]: (state, action) => {
      toast.error(action.payload);
    },
  },
});
export const {
  setSelectedProvider,
  setSelectedProviderEng,
  setSelectedService,
} = AppContent.actions;

export default AppContent.reducer;
