import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import laws from "../assets/img/HomePage/laws.svg";
import ModalAbout from "../components/ModalAbout";
import { makeStyles } from "@mui/styles";
import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import Options from "../components/Options";
import Search from "../assets/img/Entities/SearchLaw.svg";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import { changeTab } from "../redux/TabSlice";
import { getTilte } from "../helpers/utils";
import { setSelectedLaw } from "../redux/OffenceSlice";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useTheme } from "@emotion/react";
import ArrowBack from "../assets/img/HumQadamHome/arrowBack.svg";

const useStyles = makeStyles((theme) => ({
  bgcolor: {
    background: "#FFFFFF",
  },
  textColor: {
    color: theme.palette.laws.main,
  },
  input: {
    "&::placeholder": {
      color: theme.palette.laws.main,
    },
  },
  headingColor: {
    color: "#271258",
  },

  circle: {
    background: theme.palette.laws.main,
    width: "8px",
    height: "8px",
    borderRadius: "50%",
    marginTop: "8px",
  },

  sectionbg: {
    background: theme.palette.primary.main,
  },

  sectionBgLight: {
    backgroundColor: "#FFE2E3",
  },

  lawsBg: {
    backgroundColor: theme.palette.laws.light,
  },

  lawsTextBg: {
    backgroundColor: theme.palette.laws.main,
  },

  fullScreen: {
    minHeight: "100vh",
  },
  height: {
    maxHeight: "50vh",
  },
}));

function RelevantLaws() {
  const theme = useTheme();
  const classes = useStyles();
  const [openAbout, setOpenAbout] = useState(false);
  const [openItem, setOpenItem] = useState(false);
  const { selectedLaw } = useSelector((state) => state.offence);
  const { province } = useSelector((state) => state.province);
  const { appContent } = useSelector((state) => state.appContent);
  const { isEnglish } = useSelector((state) => state.language);
  const [penalTitle, setPenalTitle] = useState("");
  const [lawsTitle, setLawsTitle] = useState("");
  const [penalDesTitle, setPenalDesTitle] = useState("");
  const [relLawsTitle, setRelLawsTitle] = useState("");
  const [lawsProvince, setLawsProvince] = useState({});
  const handleOpen = () => setOpenAbout(true);
  const handleClose = () => setOpenAbout(false);

  const handleCloseItem = () => setOpenItem(false);
  const [tabSelect, setTabSelect] = useState("RelevantLaws");
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setSelectedLaw(null));
    dispatch(changeTab(tabSelect));
  }, [tabSelect]);

  const getProvince = (id) => {
    for (let i = 0; i < province?.length; i++) {
      if (id === province[i]._id) {
        return province[i].name;
      }
    }
  };

  useEffect(() => {
    if (appContent) {
      setPenalTitle(getTilte(appContent, "Penal Code Provisions"));
      setPenalDesTitle(getTilte(appContent, "Sections"));
      setLawsTitle(getTilte(appContent, "Laws"));
      setRelLawsTitle(getTilte(appContent, "Relevant Laws"));
    }
  }, [appContent]);

  const data = [
    {
      id: 1,
      text: "About",
    },
    {
      id: 2,
      text: "Partners",
    },
    {
      id: 3,
      text: "Privacy Policy",
    },
    {
      id: 4,
      text: "Terms and Conditions",
    },
  ];

  useEffect(() => {
    if (selectedLaw) {
      const lawsByProvince = {};

      selectedLaw.laws.forEach((law) => {
        const province = law.province;
        if (!lawsByProvince[province]) {
          lawsByProvince[province] = [];
        }
        lawsByProvince[province].push(law);
      });
      console.log(lawsByProvince);
      setLawsProvince(lawsByProvince);
    }
  }, [selectedLaw]);

  const items = [
    {
      id: 1,
      text: "About",
      num: "15",
    },
    {
      id: 2,
      text: "Partners",
      num: "15",
    },
    {
      id: 3,
      text: "Privacy Policy",
      num: "15",
    },
    {
      id: 3,
      text: "Privacy Policy",
      num: "15",
    },
    {
      id: 3,
      text: "Privacy Policy",
      num: "15",
    },
    {
      id: 3,
      text: "Privacy Policy",
      num: "15",
    },
    {
      id: 3,
      text: "Privacy Policy",
      num: "15",
    },
    {
      id: 4,
      text: "Terms and Conditions",
      num: "15",
    },
    {
      id: 4,
      text: "Terms and Conditions",
      num: "15",
    },
    {
      id: 4,
      text: "Terms and Conditions",
      num: "15",
    },
    {
      id: 4,
      text: "Terms and Conditions",
      num: "15",
    },
    {
      id: 4,
      text: "Terms and Conditions",
      num: "15",
    },
    {
      id: 4,
      text: "Terms and Conditions",
      num: "15",
    },
  ];
  if (!selectedLaw)
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          height: "300px",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  return (
    <div className={`${classes.lawsBg} mb-40 flex flex-col justify-between`}>
      <div>
        <Header />
        <div className="flex-col md:flex-row flex justify-between">
          <div className=" block md:hidden mb-5">
            <Options handleOpen={handleOpen} />
          </div>
          <div className="flex mx-5 mt-2 mb-3 content-center items-center">
            <img className="w-6 mr-3 mb-2" src={laws} alt="Emergency" />
            <div className="flex flex-col md:flex-row">
              <Typography
                onClick={() => setTabSelect("Laws")}
                className="mr-3 cursor-pointer"
                color={"black"}
                variant="p"
              >
                {relLawsTitle} {">"}
              </Typography>
              <Typography
                className={`${classes.headingColor} font-bold`}
                variant="p"
              >
                {selectedLaw?.title}
              </Typography>
            </div>
          </div>
          <div className=" hidden md:block">
            <Options handleOpen={handleOpen} />
          </div>
        </div>
        <div className="flex flex-col text-lg my-2 items-start mx-auto">
          {selectedLaw?.sections?.length > 0 && (
            <>
              {" "}
              <div className={`${classes.lawsTextBg} w-full py-4 mb-2`}>
                <div className="text-center">
                  <Typography
                    className={` self-center text-lg blockfont-bold`}
                    color={"white"}
                    variant="p"
                  >
                    {penalTitle}
                  </Typography>
                </div>
              </div>
              <Typography
                className={`${classes.textColor} text-center my-2 w-full`}
                variant="p"
              >
                {penalDesTitle}
              </Typography>
            </>
          )}
          <div className="pr-5 mx-auto w-8/12">
            {selectedLaw?.sections?.map((sec) => (
              <div
                key={sec._id}
                className={clsx(
                  "flex",
                  isEnglish ? "flex-row justify-start" : "flex-row-reverse"
                )}
              >
                <div>
                  <div className={`${classes.circle} mt-4`}></div>
                </div>
                <Typography
                  className={`${classes.textColor} text-base block m-2 ${
                    isEnglish ? "text-left" : "text-right"
                  }`}
                  variant="p"
                >
                  {sec}
                </Typography>
              </div>
            ))}
          </div>
        </div>
        {selectedLaw?.laws?.length > 0 && (
          <div className={`${classes.lawsTextBg} w-full py-4`}>
            <div className="text-center">
              <Typography
                className={` self-center text-lg blockfont-bold`}
                color={"white"}
                variant="p"
              >
                {lawsTitle}
              </Typography>
            </div>
          </div>
        )}
        <div className="w-full bg-white">
          <div
            className={clsx(
              "w-8/12 mx-auto pt-5 pb-9 flex flex-col",
              isEnglish ? "" : "pr-20"
            )}
          >
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              {Object.keys(lawsProvince).map((key, index) => (
                <Grid item xs={12} md={6}>
                  <div key={key} className="mb-3">
                    <ul className="">
                      <li className={clsx(classes.textColor)}>
                        <div
                          className={clsx(
                            "list-disc flex",
                            isEnglish ? "flex-row" : "flex-row-reverse"
                          )}
                        >
                          <div className={`${classes.circle}`}></div>
                          <Typography
                            variant="p"
                            className={clsx(
                              classes.textColor,
                              "block font-bold text-xl mb-1 mx-2"
                            )}
                          >
                            {getProvince(key)}
                          </Typography>
                        </div>
                        {lawsProvince[key]?.map((law, index) => (
                          <Typography
                            variant="p"
                            color={"black"}
                            className={clsx(
                              "text-md font-medium flex ml-4 mb-2",
                              isEnglish
                                ? "flex-row"
                                : "flex-row-reverse text-right"
                            )}
                          >
                            <a
                              href={law.website_link}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {isEnglish ? "-" : ""} {law.title}
                              <OpenInNewIcon
                                fontSize="small"
                                sx={{
                                  color: theme.palette.laws.main,
                                  marginLeft: 1,
                                }}
                              />
                            </a>
                          </Typography>
                        ))}
                      </li>
                    </ul>
                  </div>
                </Grid>
              ))}
            </Grid>
          </div>
          <img
            alt="Arrow Back"
            onClick={() => setTabSelect("Laws")}
            src={ArrowBack}
            className="mt-16 cursor-pointer self-start ml-5"
          />
        </div>
      </div>
      <div>
        <ModalAbout open={openAbout} handleClose={handleClose} />
      </div>
    </div>
  );
}

export default RelevantLaws;
