import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
import { getTranlation } from "../helpers/utils";

export const getDistrict = createAsyncThunk(
  "DistrictSlice/getDistrict",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_LOCAL_API}/districts`
      );
      return { langId: data.selLanguageId, res: response.data.data.Districts };
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.response.data);
    }
  }
);

export const DistrictSlice = createSlice({
  name: "district",
  initialState: {
    district: [],
    allDistricts: [],
    provDistrict: [],
    selDistrictId: "",
  },
  reducers: {
    setDistrictId: (state, action) => {
      state.selDistrictId = action.payload;
    },
    getTranslatedDistricts: (state, action) => {
      state.allDistricts = action.payload.districts.map((lang) => {
        let transDistrict = { ...lang };
        transDistrict.name = getTranlation(lang.name, action.payload.langId);
        return transDistrict;
      });
    },
    setProvDistrict: (state, action) => {
      state.provDistrict = action.payload;
    },
    setDistrict: (state, action) => {
      state.district = action.payload;
    },
  },
  extraReducers: {
    [getDistrict.fulfilled]: (state, action) => {
      state.allDistricts = action.payload.res.map((lang) => {
        let transDistrict = { ...lang };
        transDistrict.name = getTranlation(lang.name, action.payload.langId);
        return transDistrict;
      });
      state.allDistricts = state.allDistricts.sort((a, b) =>
        a.name.localeCompare(b.name)
      );
    },
    [getDistrict.rejected]: (state, action) => {
      toast.error(action.payload);
    },
  },
});
export const {
  getTranslatedDistricts,
  setDistrictId,
  setProvDistrict,
  setDistrict,
} = DistrictSlice.actions;

export default DistrictSlice.reducer;
