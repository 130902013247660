import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPages } from "../redux/PageSlice";
import Header from "../components/Header";
import { makeStyles } from "@mui/styles";
import Options from "../components/Options";
import ModalAbout from "../components/ModalAbout";
import Typography from "@mui/material/Typography";
import { Box, CircularProgress } from "@mui/material";
import UNTF from "../assets/img/HomePage/UNTF.svg";
import lums from "../assets/img/HomePage/LUMS1.svg";
import shirkatGah from "../assets/img/HomePage/shirkat.svg";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  bgcolor: {
    background: "#FFFFFF",
  },
  textColor: {
    color: theme.palette.primary.main,
  },

  sectionbg: {
    background: theme.palette.primary.main,
  },
  sectionBgLight: {
    backgroundColor: "#F6F1F4",
  },
  fullScreen: {
    minHeight: "100vh",
  },
  input: {
    width: "500px",
    border: "1px solid #AE4971",
    display: "block",
    margin: "15px 0",
    "&::placeholder": {
      color: theme.palette.primary.main,
    },
    [theme.breakpoints.down("sm")]: {
      width: "300px",
    },
  },
}));

function About() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [openAbout, setOpenAbout] = useState(false);
  const handleOpen = () => setOpenAbout(true);
  const handleClose = () => {
    setOpenAbout(false);
  };
  const { selLanguageId, isEnglish } = useSelector((state) => state.language);
  const [data, setData] = useState([]);
  const { tab } = useSelector((state) => state.tab);
  const { pages } = useSelector((state) => state.page);
  const [aboutTitle, setAboutTitle] = useState("");
  const [partnersTitle, setPartnersTitle] = useState("");
  const [privacyTitle, setPrivacyTitle] = useState("");
  const [termsTitle, setTermsTitle] = useState("");

  useEffect(() => {
    dispatch(getPages({ selLanguageId }));
  }, [selLanguageId]);

  useEffect(() => {
    if (pages.length > 0) {
      setPartnersTitle(pages.find((x) => x.key === "Partners"));
      setPrivacyTitle(pages.find((x) => x.key === "Privacy Policy"));
      setTermsTitle(pages.find((x) => x.key === "Terms and Conditions"));
      setAboutTitle(pages.find((x) => x.key === "About Us"));
    }
  }, [pages]);

  useEffect(() => {
    if (tab === "About") {
      setData(aboutTitle);
    } else if (tab === "Partners") {
      setData(partnersTitle);
    } else if (tab === "Privacy") {
      setData(privacyTitle);
    } else {
      setData(termsTitle);
    }
  }, [tab, aboutTitle, partnersTitle, privacyTitle, termsTitle]);

  const images = [
    {
      id: 3,
      image: shirkatGah,
    },
    {
      id: 1,
      image: UNTF,
    },
    {
      id: 2,
      image: lums,
    },
  ];

  return (
    <div
      className={`${classes.sectionBgLight} flex flex-col pb-20 justify-between`}
    >
      <div>
        <Header />
        <Options handleOpen={handleOpen} />
        {data ? (
          <div className="px-5 mt-2 pb-10">
            <h6
              className={`text-center mb-8 text-2xl ${classes.textColor} font-bold`}
            >
              {data?.title}
            </h6>
            <div className="flex flex-col sm:flex-row justify-center items-center w-full px-10">
              {tab === "Privacy" || tab === "Terms" ? (
                <div dangerouslySetInnerHTML={{ __html: data?.description }} />
              ) : (
                <p
                  style={{ whiteSpace: "pre-wrap" }}
                  className={clsx(isEnglish ? "text-left" : " text-right")}
                >
                  {data?.description}
                </p>
              )}
            </div>
          </div>
        ) : (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress />
          </Box>
        )}
      </div>
      {tab === "Partners" && (
        <div className="flex px-4 justify-center flex-col md:flex-row">
          {images.map((img) => (
            <div key={img.id} className="place-self-center">
              <img
                src={img.image}
                alt="partners"
                className="inline-flex items-center justify-center pl-3 py-3 text-base font-medium h-28 w-40 mx-3"
              />
            </div>
          ))}
        </div>
      )}
      <div>
        <ModalAbout open={openAbout} handleClose={handleClose} />
      </div>
    </div>
  );
}

export default About;
