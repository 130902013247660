import React, { useEffect, useState } from "react";
import settings from "../assets/img/HumQadamHome/settings.svg";
import about from "../assets/img/HumQadamHome/aboutus.svg";
import form from "../assets/img/HumQadamHome/form.svg";
import location from "../assets/img/HumQadamHome/location.svg";
import { useDispatch, useSelector } from "react-redux";
import { changeTab } from "../redux/TabSlice";
import { toast } from "react-toastify";
import { setBackFromSettings } from "../redux/PageSlice";
import ModalSettings from "./ModalSettings";

function Options({ handleOpen, setShowData }) {
  const { district, selDistrictId } = useSelector((state) => state.district);
  const { province, selProvinceId } = useSelector((state) => state.province);
  const [openAbout, setOpenAbout] = useState(false);
  const { tab } = useSelector((state) => state.tab);
  const [pro, setPro] = useState();
  const [dis, setDis] = useState();
  const dispatch = useDispatch();

  const handleClose = () => {
    if (!selDistrictId) {
      toast.error("Select District");
      return;
    }
    setOpenAbout(false);
  };

  useEffect(() => {
    if (district) {
      for (let i = 0; i < district.length; i++) {
        if (district[i]._id === selDistrictId) {
          setDis(district[i]);
          return;
        }
        setDis("");
      }
    }
  }, [selDistrictId, district]);

  useEffect(() => {
    if (province) {
      province.forEach((prov) => {
        if (prov._id === selProvinceId) {
          setPro(prov);
          return;
        }
      });
    }
  }, [selProvinceId, province]);

  const handlClick = (tabS) => {
    if (!selDistrictId) {
      toast.error("Select District");
      return;
    }
    if (setShowData) setShowData([]);
    dispatch(setBackFromSettings(tab));
    dispatch(changeTab(tabS));
  };
  const handleAbout = () => {
    if (!selDistrictId) {
      toast.error("Select District");
      return;
    }
    handleOpen();
  };
  return (
    <div className="flex flex-col items-end justify-end mt-2">
      <div className="flex m-2">
        <img
          onClick={() => handleAbout()}
          className="px-2 w-11 cursor-pointer"
          alt="About"
          src={about}
        />
        <div onClick={() => handlClick("Contact")}>
          <img className="px-2 w-11 cursor-pointer" alt="Form" src={form} />
        </div>
        <div onClick={() => handlClick("Settings")}>
          <img
            className="px-2 w-11 cursor-pointer"
            alt="Settings"
            src={settings}
          />
        </div>
      </div>
      <div
        onClick={() => setOpenAbout(true)}
        className="cursor-pointer underline flex mx-2 mr-5"
      >
        <p>
          {dis?.name}, {pro?.name}
        </p>
      </div>
      <ModalSettings open={openAbout} handleClose={handleClose} />
    </div>
  );
}

export default Options;
